import styled from 'styled-components/macro';
import { Link as ReachRouterLink } from 'react-router-dom';

export const Input = styled.input`
  background: #333;
  border-radius: 4px;
  border 0;
  color: #fff;
  height: 40px;
  padding: 5px 20px;
  margin-bottom: 0px;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  }
`;

export const SmallInput = styled.input`
  background: #333;
  border-radius: 3px;
  border 0;
  color: #fff;
  height: 15px;
  padding: 5px 0px;
  length: 10px;
  margin-bottom: 0px;
  margin-top: 0px;
  margin-left: 5px;
  margin-right: 5px;
  }
`;

export const Select = styled.select`
  display: block;
  background: #333;
  border-radius: 4px;
  border 0;
  color: #fff;
  height: 40px;
  line-height: 40px;
  padding: 5px 5px;
  margin-bottom: 0px;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
`;

export const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  display: inline-block;
  accent-color: #49b9ec;
  width: 40px;
  height: 20px;
  background: #303030
  border-radius: 3px;
  transition: all 150ms;
`;

export const Title = styled.p`
  height: fit-content;
  margin-top: 30px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 24px;
  color: #e5e5e5;
  font-weight: bold;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  //margin-bottom: 5px;
  > ${Title} {
    @media (max-width: 1000px) {
      margin-left: 30px;
    }
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const LongContainer = styled.div`
  display: flex;
  flex-direction: row;
  //justify-content: bottom;
  margin-bottom: 10px;
  width: fit-content;
  justify-content: space-between;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: bottom;
  margin-bottom: 10px;
  }
`;

export const PlainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: bottom;
  }
`;

export const ButtonLink = styled(ReachRouterLink)`
  display: block;
  background-color: rgba(200,100,20,0.50);
  height: fit-content;
  text-align: center;
  margin: 10px 10px 10px 10px;
  color: white;
  border: 0;
  font-size: 15px;
  border-radius: 4px;
  padding: 8px 17px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background: rgba(220,140,30,0.90);
  }
`;

export const TextLink = styled.p`
color: #fff;
text-decoration: none;
white-space: nowrap;
margin-right: 15px;
margin-left: 30px;
margin-top: 30px;
font-weight: ${({ active }) => (active === 'true' ? '700' : 'normal')};
cursor: pointer;
&:hover {
  font-weight: bold;
}
&:last-of-type {
  margin-right: 0;
}
`;

export const ButtonDownload = styled.p`
  display: block;
  background-color: rgba(200,100,20,0.50);
  height: fit-content;
  text-align: center;
  margin: 10px 10px 10px 10px;
  color: white;
  border: 0;
  font-size: 15px;
  border-radius: 4px;
  padding: 8px 17px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background: rgba(220,140,30,0.90);
  }
`;

export const Button = styled.p`
  display: block;
  background-color: rgba(200,100,200,0.50);
  height: fit-content;
  text-align: center;
  margin: 5px 10px 10px 10px;
  color: white;
  border: 0;
  font-size: 15px;
  border-radius: 4px;
  padding: 8px 17px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background: rgba(200,100,200,0.90);
  }
`;

export const TestLine = styled.p`
  display: flex;
  background-color: rgba(0,0,0,0.5);
  height: fit-content;
  text-align: left;
  margin: 1px 2px;
  color: white;
  border: 0;
  font-size: 12px;
  border-radius: 4px;
  padding: 5px 5px;
  //cursor: pointer;
  text-decoration: none;
  &:hover {
    background: rgba(0,0,0,0.9);
  }
`;


export const ButtonSpacer = styled.p`
  display: block;
  background-color: rgba(0,0,0,0);
  height: fit-content;
  text-align: center;
  margin: 5px 10px 10px 10px;
  color: rgba(0,0,0,0);
  border: 0;
  font-size: 15px;
  border-radius: 4px;
  padding: 8px 17px;
  cursor: pointer;
  text-decoration: none;
`;



export const ActionItem = styled.p`
  display: block;
  background-color: rgba(50,100,200,0.50);
  height: fit-content;
  text-align: center;
  margin: 18px 10px 0px 10px;
  color: white;
  border: 0;
  font-size: 15px;
  border-radius: 8px;
  padding: 8px 8px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background: rgba(25,50,100,0.90);
    font-weight: bold;
  }
`;

export const CompactActionItem = styled.p`
  display: block;
  background-color: rgba(178,178,102,0.50);
  height: fit-content;
  text-align: center;
  margin: 2px 5px 10px 5px;
  color: white;
  border: 0;
  font-size: 12px;
  border-radius: 4px;
  padding: 3px 25px 3px 25px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background: rgba(178,178,102,0.90);
    font-weight: bold;
  }
`;

export const Group = styled.div`
  margin: 56px 56px;
  display: flex;
  flex-direction: ${({ flexDirection }) => (flexDirection === 'row' ? 'row' : 'column')};
  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
  ${({ margin }) => margin && `margin: ${margin}`};
  > ${Container}:first-of-type {
    @media (min-width: 1100px) {
      margin-top: 10px;
    }
  }
`;

export const SubTitle = styled.p`
  height: fit-content;
  text-align: left;  
  margin-top: 1px;
  margin-bottom: 2px;
  margin-left: 7px;
  //margin: 1px, 1px, 1px, 1px;
  margin-right: 7px;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  user-select: none;
`;


export const CloseTitle = styled.p`
  
  text-align: left;  
  margin-top: 12px;
  margin-bottom: 0px;
  margin-left: 7px;
  margin-right: 7px;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  user-select: none;
`;

export const Text = styled.p`
  margin-top: 1px;
  margin-left: 7px;
  font-size: 10px;
  color: #fff;
  user-select: none;
  line-height: normal;
`;

export const ActionText = styled.p`
margin-top: auto; /* Pushes the element to the bottom of its flex container */
margin-left: 7px;
font-size: 9px;
color: #eee;
user-select: none;
line-height: normal;
cursor: pointer;

&:hover {
  font-weight: bold;
}
`;

export const DividingLine = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 1px;
  background-color: #fff;
`;

export const Entities = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 56px;
`;

export const Meta = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  padding: 10px;
  background-color: #0000008f;
`;

export const Image = styled.img`
  border: 0;
  width: 100%;
  max-width: 305px;
  cursor: pointer;
  height: auto;
  padding: 0;
  margin: 0;
`;


export const Heading = styled.h2`
  color: white;
  font-size: 50px;
  line-height: normal;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.45);
  margin-top: 10px;
  margin-bottom: 3px;
`;

export const Item = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
  width: 200px;
  padding: 10px;
  position: relative;
  cursor: pointer; 
  border-radius: 12px;
  border: 1px solid grey;
  //background-color: ${props => props.backgroundColor || 'rgba(51, 223, 255, 0.4)'};
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.3);
    z-index: 99;
    background-color: rgba(51, 223, 255, 0.8);
  }
  @media (min-width: 1200px) {
    &:hover ${Meta}, &:hover ${Text}, &:hover ${SubTitle} {
      display: block;
      z-index: 100;
    }
  }
  /* &:first-of-type {
    margin-left: 56px;
    @media (max-width: 1000px) {
      margin-left: 30px;
    }
  }
  &:last-of-type {
    margin-right: 56px;
    @media (max-width: 1000px) {
      margin-right: 30px;
    } */
  }
`;

export const FeatureText = styled.p`
  font-size: 18px;
  color: white;
  font-weight: ${({ fontWeight }) => (fontWeight === 'bold' ? 'bold' : 'normal')};
  margin: 0;
  @media (max-width: 600px) {
    line-height: 22px;
  }
`;

export const Feature = styled.div`
  display: flex;
  flex-direction: row;
  background: url(${({ src }) => src});
  background-size: contain;
  position: relative;
  height: 360px;
  background-position-x: right;
  background-repeat: no-repeat;
  background-color: black;
  @media (max-width: 1000px) {
    height: auto;
    background-size: auto;
    ${Title} {
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 10px;
    }
    ${FeatureText} {
      font-size: 14px;
    }
  }
`;

export const FeatureTitle = styled(Title)`
  margin-left: 0;
`;

export const FeatureClose = styled.button`
  color: white;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  img {
    filter: brightness(0) invert(1);
    width: 24px;
  }
`;


export const Content = styled.div`
  margin: 56px;
  max-width: 500px;
  line-height: normal;
  @media (max-width: 1000px) {
    margin: 30px;
    max-width: none;
  }
`;

export const Maturity = styled.div`
  background-color: ${({ rating }) => (rating >= 15 ? '#f44336' : '#2f9600')};
  border-radius: 15px;
  width: 28px;
  line-height: 28px;
  text-align: center;
  color: white;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  margin-right: 10px;
  font-size: 12px;
`;