//move a batch of tests from order_items to test_results
//useful when some phlebotomist carelessly entered the wrong requisition number and the 
// test get stuck as an order becuase the results processing funcitons couldn't find them
import { getFirestore, collection, query, where, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';

const db = getFirestore();

export default async function moveToResults(props) {

  const requisitionNumber  = props;

      try {
        // 1. Query for order items with the given requisition number
        const orderItemsRef = collection(db, 'order_items');
        const q = query(orderItemsRef, where('requisition_number', '==', requisitionNumber));
        const querySnapshot = await getDocs(q);

        // 2. Loop through the documents and move them
        querySnapshot.forEach(async (doc) => {
          const orderItemData = doc.data();

          // 3. Add the document to the test_results collection
          await addDoc(collection(db, 'test_results'), orderItemData);

          // 4. Delete the document from the order_items collection
          await deleteDoc(doc.ref);
        });
      } catch (e) {
        console.error('Error moving documents: ' + e.message);
      }
};
