import React, { useState, useEffect } from 'react';
import { Header, Results, Form } from '../components';
import * as ROUTES from '../constants/routes';
import logo from '../logo.png';
import { FooterContainer } from '../containers/footer';
import { getFunctions, httpsCallable } from 'firebase/functions';
import styled from 'styled-components';

// Spinner component (same as before)
const Spinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-left:   
 10px; 

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg);   
 }
  }
`;

// Overlay component
const Overlay = styled.div`
  position: fixed; /* Stay in place even when scrolling */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(50, 50, 50, 0.5); /* Semi-transparent background   
 */
  z-index: 1000; /* Ensure it's on top of other elements */
`;

export default function ProcessResults() {
  const [mobileNumber, setMobileNumber] = useState('');
  const [requisitionNumber, setRequisitionNumber] = useState('');
  const [msgCtrlId, setMsgCtrlId] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [queue, setQueue] = useState([]);
  
  const [isLoading, setIsLoading] = useState(false);
  const [isDeletingMessage, setIsDeletingMessage] = useState(false);
  const [isFetchingItem, setIsFetchingItem] = useState(false);
  const [isFetchingOBX, setIsFetchingOBX] = useState(false);
  const [isFetchingQueue, setIsFetchingQueue] = useState(false);  
  const [ isSendingEmail,  setIsSendingEmail] = useState(false);
  const [ isSendingWhatsApp,  setIsSendingWhatsApp] = useState(false);
  const [isStoringData, setIsStoringData] = useState(false);
  const [ isUpdatingData,  setIsUpdatingData] = useState(false);
  
  
  // const [selectedMsgCtrl, setSelectedMsgCtrl] = useState(null);
  const [apiMessage, setApiMessage] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [sortOrder, setSortOrder] = useState(null);
  const [obxSortOrder, setObxSortOrder] = useState(null);
  const [obxData, setObxData] = useState([]);
  const [queueItemData, setQueueItemData] = useState({});
  const [patientName, setPatientName] = useState("");

  useEffect(() => {
    setIsLoading(isDeletingMessage || isFetchingItem || isFetchingOBX || 
      isFetchingQueue || isSendingEmail || isSendingWhatsApp || isStoringData || isUpdatingData);
      
  }, [isDeletingMessage, isFetchingItem, isFetchingOBX, isFetchingQueue,
      isSendingEmail, isSendingWhatsApp, isStoringData, isUpdatingData]);

  const fetchOBXData = async () => {
    try {
      setObxData([]);
      if (!requisitionNumber || requisitionNumber === "") return;

        setIsFetchingOBX(true);
        setApiMessage("Fetching OBX Data from the Results Database");
        // 1. Get the Cloud Function
        const functions = getFunctions();
        const serveDataOBX = httpsCallable(functions, 'serveDataOBX');

        // 2. Call the Cloud Function with the requisition number
        const result = await serveDataOBX({ requisitionNumber }); 

        // 3. Update the state with the received data
        setObxData(result.data.obxData); 

        console.log(result.data.message); // Log success message
        console.log(result.data.messageSid); // Log message SID (optional)
        setApiMessage(result.data.message);
  } catch (error) {
    // Handle error
    console.error(error.message); // Log error message
    console.error(error.details); // Log error details (if available)
    setApiMessage(error.message, error.details);
  } finally {
      setIsFetchingOBX(false); // Set isLoading to false after the API call
    }
  };
  

  useEffect(() => {
    fetchOBXData();
  }, [requisitionNumber]);

  const handleHeaderClick = (header) => {
    // Determine the new sort order
    const newSortOrder =
      sortOrder === "asc"? "desc": "asc";

    setSortOrder(newSortOrder);

    // Sort the queue based on the new sort order
    const sortedQueue = [...queue].sort((a, b) => {
      const valueA = a[header];
      const valueB = b[header];

      if (newSortOrder === "asc") {
        return valueA.localeCompare(valueB); // For string comparison
      } else if (newSortOrder === "desc") {
        return valueB.localeCompare(valueA);
      } else {
        return 0; // No sorting
      }
    });

    setQueue(sortedQueue); // Update the queue state
  };

  const handleOBXHeaderClick = (header) => {
    const newSortOrder = obxSortOrder === "asc" ? "desc" : "asc"; 
    setObxSortOrder(newSortOrder);
  
    const sortedData = [...obxData].sort((a, b) => {
      const valueA = a[header];
      const valueB = b[header];
  
      // Check if the values are numbers
      if (!isNaN(parseFloat(valueA)) && !isNaN(parseFloat(valueB))) {
        // Compare as numbers
        if (newSortOrder === "asc") {
          return parseFloat(valueA) - parseFloat(valueB);
        } else {
          return parseFloat(valueB) - parseFloat(valueA);
        }
      } else {
        // Compare as strings
        if (newSortOrder === "asc") {
          return valueA.localeCompare(valueB);
        } else {
          return valueB.localeCompare(valueA);
        }
      }
    });
  
    setObxData(sortedData);
  };


  const handleRowClick = (msgctrl, req, name) => {
    setObxData([]);
    setQueueItemData({});
    setMsgCtrlId(msgctrl);
    setRequisitionNumber(req);
    setPatientName(name);
  };

  const handleOBXRowClick = (msgctrl, req) => {
    //setMsgCtrlId(msgctrl);
    //setRequisitionNumber(req);
  };

  // useEffect to clear the API message after a delay
  useEffect(() => {
    let timeoutId;
    if (apiMessage) {
      timeoutId = setTimeout(() => {
        setApiMessage(null);
      }, 5000); // Clear message after 2 seconds
    }

  return () => clearTimeout(timeoutId); // Clear timeout on unmount or when apiMessage changes
}, [apiMessage]);

  const sendWhatsappResult = async (mobileNumber, requisitionNumber) => {
    try {
      setIsSendingWhatsApp(true);
      setApiMessage("Sending Test Results by WhatsApp");
      // Get the Cloud Function
      const functions = getFunctions(); 
      const releaseResultWhatsapp = httpsCallable(functions, 'releaseResultWhatsapp');
  
      // Call the Cloud Function with data and authentication
      const result = await releaseResultWhatsapp({ mobileNumber, requisitionNumber });
  
      // Handle success
      console.log(result.data.message); // Log success message
      console.log(result.data.messageSid); // Log message SID (optional)
      setApiMessage(result.data.message);
      // ... update UI or perform other actions based on success
    } catch (error) {
      // Handle error
      console.error(error.message); // Log error message
      console.error(error.details); // Log error details (if available)
      setApiMessage(error.message, error.details);
    } finally {
        setIsSendingWhatsApp(false); // Set isLoading to false after the API call
      }
  };
  
  const sendEmailResult = async (emailAddress, requisitionNumber) => {
    try {
      setIsSendingEmail(true);
      setApiMessage("Sending test Results by Email");
      // Get the Cloud Function
      const functions = getFunctions(); 
      const releaseResultsEmail = httpsCallable(functions, 'releaseResultsEmail');
  
      // Call the Cloud Function with data and authentication
      const result = await releaseResultsEmail({ emailAddress, requisitionNumber });
  
      // Handle success
      console.log(result.data.message); // Log success message
      console.log(result.data.messageSid); // Log message SID (optional)
      setApiMessage(result.data.message);
    } catch (error) {
      // Handle error
      console.error(error.message); // Log error message
      console.error(error.details); // Log error details (if available)
      setApiMessage(error.message, error.details);
      // ... display error message to the user or handle the error appropriately
    } finally {
      setIsSendingEmail(false); // Set isLoading to false after the API call
    }
  };

  const fetchHL7Queue = async () => {
    try {
      setObxData([]);
      setQueueItemData({});
      setRequisitionNumber(null);
      setMsgCtrlId(null);
      setPatientName("");
      setQueue([]);

      setIsFetchingQueue(true);
      setApiMessage("Fetching Latest Unprocessed Results from the Server");
      const functions = getFunctions();
      const getHL7Queue = httpsCallable(functions, 'getHL7Queue');
      const result = await getHL7Queue(); // No need to pass data
      setQueue(result.data.queue); 
    } catch (error) {
      console.error(error.message); // Log error message
      console.error(error.details); // Log error details (if available)
      setApiMessage(error.message, error.details);  
    } finally {
      setIsFetchingQueue(false); // Set isLoading to false after the API call
    }
  };

  const deleteHL7Message = async (msgCtrlId) => {
    try {
      setIsDeletingMessage(true);
      setApiMessage("Removing HL7 Message form the server queue");
      const functions = getFunctions();
      const removeHL7Message = httpsCallable(functions, 'removeHL7Message');
      const result = await removeHL7Message(msgCtrlId); // No need to pass data
    } catch (error) {
      console.error(error.message); // Log error message
      console.error(error.details); // Log error details (if available)
      setApiMessage(error.message, error.details); 
    } finally {
      setIsDeletingMessage(false); // Set isLoading to false after the API call
    }
  };


  useEffect(() => {
    const fetchHL7QueueItem = async () => {
      if (!msgCtrlId || msgCtrlId === "") return;

      try {
        setIsFetchingItem(true);
        setApiMessage("Fetching HL7 Item from the Server");
        const functions = getFunctions();
        const fetchHL7QueueItem = httpsCallable(functions, 'serveHL7QueueItem');
        const result = await fetchHL7QueueItem({ msgCtrlId }); 
        setQueueItemData(result);
      } catch (error) {
        console.error('Error fetching HL7 queue item:', error);
        setApiMessage(error.message, error.details);
      } finally {
        setIsFetchingItem(false); // Set isLoading to false after the API call
        setShowSpinner(true); // Show spinner after API call completes
        // Hide spinner after 5 seconds
        setTimeout(() => {
          setShowSpinner(false);
        }, 5000);
      }
    };
    fetchHL7QueueItem();
  }, [msgCtrlId]);

  const storeHL7Results = async (msg_CtrlId, req_Number) => {
    try {
      setIsStoringData(true);
      setApiMessage("Attempting to store results message in HL7 Results Database");
      const functions = getFunctions();
      const storeHL7Results = httpsCallable(functions, 'storeHL7Results');
      const result = await storeHL7Results({msg_CtrlId}); 
      // Handle success
      console.log(result.data.message);
      fetchOBXData();

    } catch (error) {
      console.error(error.message); // Log error message
      console.error(error.details); // Log error details (if available)
      setApiMessage(error.message, error.details);  
      // ... handle error (e.g., display an error message)
    } finally {
      setIsStoringData(false); // Set isLoading to false after the API call
    }
  };


  const removeHL7Message = async (msgCtrlId) => {
    try {
      setIsLoading(true);
      setApiMessage("Removing message from the HL7 queue on the server");
      const functions = getFunctions();
      const aknowledgeHL7Message = httpsCallable(functions, 'aknowledgeHL7Message');
      const result = await aknowledgeHL7Message({msgCtrlId}); 
      // Handle success
      console.log(result.data.message);
    } catch (error) {
      console.error(error.message); // Log error message
      console.error(error.details); // Log error details (if available)
      setApiMessage(error.message, error.details);  
    } finally {
      setIsLoading(false); // Set isLoading to false after the API call
    }
  };


  const handleAddRow = (index) => {
    // Create a new row object with default values (you can customize these)
    const newRow = {
      obsSetId: "",
      obsId: "",
      obsDescription: "",
      obsValue: "",
      obsUnit: "",
      obsReferenceRange: "",
      obsAbnormal: "",
      obsStatus: "",
      obsHeading: "", // Or get the heading from the previous row if needed
      obsDateTime: "",
      isNew: true,
    };

    // Insert the new row at the specified index
    setObxData((prevData) => {
      const newData = [...prevData];
      newData.splice(index + 1, 0, newRow);
      return newData;
    });
  };

  const handleRemoveRow = (index) => {
    setObxData((prevData) => {
      const newData = [...prevData];
      newData.splice(index, 1);
      return newData;
    });
  };

  const handleInputChange = (index, field, value) => {
    setObxData((prevData) => {
      const newData = [...prevData];
      newData[index][field] = value;

 /*      // If it's a new row and all fields are filled, mark it as not new
      if (newData[index].isNew) {
        const allFieldsFilled = Object.values(newData[index]).every(
          (val) => val !== ""
        );
        if (allFieldsFilled) {
          newData[index].isNew = false;
        }
      }
 */
      return newData;
    });
  };


async function updateHL7Data(requisitionNumber, newLines) {
  try {
    // 1. Get the Cloud Function
    setIsUpdatingData(true);
    setApiMessage("Adding New Data into the Results Database");
    const functions = getFunctions();
    const updateHL7Data = httpsCallable(functions, 'updateHL7Data');

    // 2. Call the Cloud Function with the data
    const result = await updateHL7Data({ requisitionNumber, newLines });

    // 3. Handle the response (e.g., show a success message)
    console.log(result.data.message); // Log success message
    console.log(result.data.messageSid); // Log message SID (optional)
    setApiMessage(result.data.message);
  } catch (error) {
    console.error(error.message); // Log error message
    console.error(error.details); // Log error details (if available)
    setApiMessage(error.message, error.details);  
  } finally {
    setIsUpdatingData(false); // Set isLoading to false after the API call
    setShowSpinner(true); // Show spinner after API call completes
    // Hide spinner after 5 seconds
    setTimeout(() => {
      setShowSpinner(false);
    }, 5000);
  }
}

// Example usage in your component
const handleSaveData = async () => {
  const i = 1;
  // ... gather the requisitionNumber and newLines from your component's state ...
  const newLines = obxData.filter(item => item.isNew); 

  // Call the API function to update the data
  await updateHL7Data(requisitionNumber, newLines); 
  // ... optionally update the UI or perform other actions after the data is saved ...
};


  return (
    <>
      {(isLoading || showSpinner) && ( 
        <Overlay>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}> {/* Add a container div */}
            <Spinner />
            {apiMessage && <Form.DappledText>{apiMessage}</Form.DappledText>} 
          </div>
        </Overlay>
      )}

      <Header src="joker1" dontShowOnSmallViewPort>
        <Header.Frame>
          <Header.Group>
            <Header.Logo to={ROUTES.LANDING} src={logo} alt="EZPath" />
            <Header.ButtonLink to={ROUTES.LAB}>Back to Lab Home</Header.ButtonLink>
          </Header.Group>
        </Header.Frame>
        <Header.Feature>
            <Header.FeatureCallOut>Process Results</Header.FeatureCallOut>
            <Header.Text>
              Here you can view and manage new results available in the system.
            </Header.Text>
          </Header.Feature>
        </Header>
        <Form.LongLeftContainer>
        <Header.Button onClick={() => sendWhatsappResult(mobileNumber, requisitionNumber)}>
          Whatsapp Results
        </Header.Button>
        <Form.Space></Form.Space>
        <Header.Button onClick={() => sendEmailResult(emailAddress, requisitionNumber)}>
          Email Results
        </Header.Button>
        <Form.Space></Form.Space>
        </Form.LongLeftContainer>
        <Form.Space></Form.Space>

        <div>  
      <Form.LongLeftContainer>
      <Results.CloseTitle htmlFor="mobileNumber">Mobile Number:</Results.CloseTitle>
          <Results.Input
            type="text"
            id="mobileNumber"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
          />
          <Results.CloseTitle htmlFor="requisitionNumber">Requisition Number:</Results.CloseTitle>
          <Results.Input
            type="text"
            id="requisitionNumber"
            value={requisitionNumber}
            onChange={(e) => setRequisitionNumber(e.target.value)}
          />
          <Results.CloseTitle htmlFor="msgCtrlId">Message Control ID:</Results.CloseTitle>
          <Results.Input
            type="text"
            id="msgCtrlId"
            value={msgCtrlId}
            onChange={(e) => setMsgCtrlId(e.target.value)}
          />
          <Results.CloseTitle htmlFor="emailAddress">Email Address:</Results.CloseTitle>
          <Results.Input
            type="email"
            id="emailAddress"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
          />
      </Form.LongLeftContainer>
      <h2>HL7 Queue</h2>
      <Form.LongLeftContainer>
        <Header.Button onClick={() => fetchHL7Queue(emailAddress, requisitionNumber)}>
            Get Latest Messages
          </Header.Button>
          <Header.Button onClick={() => storeHL7Results(msgCtrlId, requisitionNumber)}>
            Store Message In Database
          </Header.Button>
          <Header.Button onClick={() => deleteHL7Message(msgCtrlId)}>
            Delete Selected Message
          </Header.Button>
        </Form.LongLeftContainer>


        <Form.ExpandingContainer>
              <Form.GridText width="100px" onClick={() => handleHeaderClick("batchNormal")}>Batch Normal?</Form.GridText>
              <Form.GridText width="100px" onClick={() => handleHeaderClick("requisition")}>Requisition</Form.GridText>
              <Form.GridText width="100px" onClick={() => handleHeaderClick("msgctrl")}>Control ID</Form.GridText>
              <Form.GridText width="100px" onClick={() => handleHeaderClick("name")}>Patient Name</Form.GridText>
              <Form.GridText width="150px" onClick={() => handleHeaderClick("surname")}>Patient Surname</Form.GridText>
              <Form.GridText width="100px" onClick={() => handleHeaderClick("reportStatus")}>Final/Prelim</Form.GridText>
            </Form.ExpandingContainer>
        {queue.map((item, index) => (
            <Form.ExpandingContainer
              key={index}
              onClick={() => handleRowClick(item.msgctrl, item.requisition, item.name + " " + item.surname)}
              style={{ cursor: "pointer",
                backgroundColor: msgCtrlId === item.msgctrl ? 'lightgray' : 'transparent' 
               }}>
              <Form.GridText width="100px">{item.batchNormal}</Form.GridText>
              <Form.GridText width="100px">{item.requisition}</Form.GridText>
              <Form.GridText width="100px">{item.msgctrl}</Form.GridText>
              <Form.GridText width="100px">{item.name}</Form.GridText>
              <Form.GridText width="150px">{item.surname}</Form.GridText>
              <Form.GridText width="100px">{item.reportStatus}</Form.GridText>
            </Form.ExpandingContainer>
        ))}
    </div>


    <div>
      <h2>OBX Data for Queued HL7 Message: {msgCtrlId} - {patientName}</h2>
      {/* <Header.Button onClick={() => handleSaveData()}>Update Database</Header.Button> */}
      <Form.ExpandingContainer>
              <Form.GridText width="50px">Set ID</Form.GridText>
              <Form.GridText width="50px">Obs.ID</Form.GridText>
              <Form.GridText width="250px">Description</Form.GridText>
              <Form.GridText width="70px">Value</Form.GridText>
              <Form.GridText width="70px">Unit</Form.GridText>
              <Form.GridText width="150px">Reference Range</Form.GridText>
              <Form.GridText width="30px">H/L</Form.GridText>
              <Form.GridText width="30px">P/F</Form.GridText>
              <Form.GridText width="80px">DateTime</Form.GridText>
              <Form.GridText width="250px">Heading</Form.GridText>
            </Form.ExpandingContainer>
      {queueItemData?.data?.obxData?.map((item, index) => (
            <Form.ExpandingContainer
              key={index}
              style={{ cursor: "pointer",
                backgroundColor: msgCtrlId === item.msgctrl ? 'lightgray' : 'transparent' 
               }}>
              <>
              <Form.GridText width="50px">{item.obsSetId}</Form.GridText>
              <Form.GridText width="50px">{item.obsId}</Form.GridText>
              <Form.GridText width="250px">{item.obsDescription}</Form.GridText>
              <Form.GridText width="70px">{item.obsValue}</Form.GridText>
              <Form.GridText width="70px">{item.obsUnit}</Form.GridText>
              <Form.GridText width="150px">{item.obsReferenceRange}</Form.GridText>
              <Form.GridText width="20px">{item.obsAbnormal}</Form.GridText>
              <Form.GridText width="20px">{item.obsStatus}</Form.GridText>
              <Form.GridText width="120px">{item.obsDateTime}</Form.GridText>
              <Form.GridText width="250px">{item.obsHeading}</Form.GridText>
              <Form.GridText width="25"><button onClick={() => handleAddRow(index)}>+</button></Form.GridText>
              <Form.GridText width="25"><button onClick={() => handleRemoveRow(index)}>-</button></Form.GridText>
              </>
            </Form.ExpandingContainer>
        ))}
    </div>



    <div>
      <h2>OBX Data Stored in Database for Requisition: {requisitionNumber} - {patientName}</h2>
      <Header.Button onClick={() => handleSaveData()}>Update Database</Header.Button>
      <Form.ExpandingContainer>
              <Form.GridText width="50px" onClick={() => handleOBXHeaderClick("obsSetId")}>Set ID</Form.GridText>
              <Form.GridText width="50px" onClick={() => handleOBXHeaderClick("obsId")}>Obs.ID</Form.GridText>
              <Form.GridText width="250px" onClick={() => handleOBXHeaderClick("obsDescription")}>Description</Form.GridText>
              <Form.GridText width="70px" onClick={() => handleOBXHeaderClick("obsValue")}>Value</Form.GridText>
              <Form.GridText width="70px" onClick={() => handleOBXHeaderClick("obsUnit")}>Unit</Form.GridText>
              <Form.GridText width="150px" onClick={() => handleOBXHeaderClick("obsReferenceRange")}>Reference Range</Form.GridText>
              <Form.GridText width="20px" onClick={() => handleOBXHeaderClick("obsNormal")}>H/L</Form.GridText>
              <Form.GridText width="20px" onClick={() => handleOBXHeaderClick("obsStatus")}>P/F</Form.GridText>
              <Form.GridText width="120px" onClick={() => handleOBXHeaderClick("obsDateTime")}>DateTime</Form.GridText>
              <Form.GridText width="250px" onClick={() => handleOBXHeaderClick("obsHeading")}>Heading</Form.GridText>
            </Form.ExpandingContainer>
      {obxData.map((item, index) => (
            <Form.ExpandingContainer
              key={index}
              style={{ cursor: "pointer",
                backgroundColor: msgCtrlId === item.msgctrl ? 'lightgray' : 'transparent' 
               }}>
                {item.isNew?
                <>
              <Form.LeftText type="text" style={{ width: '50px' }} value={item.obsSetId} onChange={(e) => handleInputChange(index, "obsSetId", e.target.value)}></Form.LeftText> 
              <Form.LeftText type="text" style={{ width: '50px' }} value={item.obsId} onChange={(e) => handleInputChange(index, "obsId", e.target.value)}></Form.LeftText> 
              <Form.LeftText type="text" style={{ width: '250px' }} value={item.obsDescription} onChange={(e) => handleInputChange(index, "obsDescription", e.target.value)}></Form.LeftText>
              <Form.LeftText type="text" style={{ width: '70px' }} value={item.obsValue} onChange={(e) => handleInputChange(index, "obsValue", e.target.value)}></Form.LeftText>
              <Form.LeftText type="text" style={{ width: '70px' }} value={item.obsUnit} onChange={(e) => handleInputChange(index, "obsUnit", e.target.value)}></Form.LeftText>
              <Form.LeftText type="text" style={{ width: '150px' }} value={item.obsReferenceRange} onChange={(e) => handleInputChange(index, "obsReferenceRange", e.target.value)}></Form.LeftText>
              <Form.LeftText type="text" style={{ width: '20px' }} value={item.obsAbnormal} onChange={(e) => handleInputChange(index, "obsAbnormal", e.target.value)}></Form.LeftText>
              <Form.LeftText type="text" style={{ width: '20px' }} value={item.obsStatus} onChange={(e) => handleInputChange(index, "obsStatus", e.target.value)}></Form.LeftText>
              <Form.LeftText type="text" style={{ width: '120px' }} value={item.obsDateTime} onChange={(e) => handleInputChange(index, "obsDateTime", e.target.value)}></Form.LeftText>
              <Form.LeftText type="text" style={{ width: '250px' }} value={item.obsHeading} onChange={(e) => handleInputChange(index, "obsHeading", e.target.value)}></Form.LeftText>
              <Form.GridText type="text" style={{ width: '25px' }}><button onClick={() => handleAddRow(index)}>+</button></Form.GridText>
              <Form.GridText type="text" style={{ width: '25px' }}><button onClick={() => handleRemoveRow(index)}>-</button></Form.GridText>
              </>:
              <>
              <Form.GridText width="50px">{item.obsSetId}</Form.GridText>
              <Form.GridText width="50px">{item.obsId}</Form.GridText>
              <Form.GridText width="250px">{item.obsDescription}</Form.GridText>
              <Form.GridText width="70px">{item.obsValue}</Form.GridText>
              <Form.GridText width="70px">{item.obsUnit}</Form.GridText>
              <Form.GridText width="150px">{item.obsReferenceRange}</Form.GridText>
              <Form.GridText width="20px">{item.obsAbnormal}</Form.GridText>
              <Form.GridText width="20px">{item.obsStatus}</Form.GridText>
              <Form.GridText width="120px">{item.obsDateTime}</Form.GridText>
              <Form.GridText width="250px">{item.obsHeading}</Form.GridText>
              <Form.GridText width="25"><button onClick={() => handleAddRow(index)}>+</button></Form.GridText>
              <Form.GridText width="25"><button onClick={() => handleRemoveRow(index)}>-</button></Form.GridText>
              </>
              }
            </Form.ExpandingContainer>
        ))}
    </div>

      <FooterContainer />
    </>
  );
}
