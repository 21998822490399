//updated test results vat amounts and tariff codes to be correct after a bug gave htem all a common vlaue for each test batch
import { getFirestore } from "firebase/firestore";
import { collection, getDocs, deleteDoc, doc, setDoc, writeBatch, batch } from 'firebase/firestore'; 

const db = getFirestore();

export default async function updateTestResults() {
    try {
      const testsRef = collection(db, 'tests'); // Get tests collection reference
      const testsSnapshot = await getDocs(testsRef); // Fetch tests data
  
      const testsData = {};
      testsSnapshot.forEach(doc => {
        const data = doc.data();
        testsData[data.short_name] = {
          vat_amount: data.vat_amount,
          tariff_code: data.tariff_code
        };
      });
  
      const testResultsRef = collection(db, 'test_results'); // Get test_results collection reference
      const testResultsSnapshot = await getDocs(testResultsRef);
  
      const batchUpdates = writeBatch(db); // Create a batch for efficient updates
  
    testResultsSnapshot.forEach(doc => {
      const data = doc.data();
      const shortName = data.test_name;
      const reducedPriceStr = data.reduced_price.replace(',', '.'); 
      const reducedPrice = parseFloat(reducedPriceStr);

      /* if (!isNaN(reducedPrice)) {
        const vatAmount = (reducedPrice * 0.15).toFixed(2);
      } */
      
      const vatString = (reducedPrice * 0.15).toFixed(2);
      if (testsData[shortName]) {
        const updateData = {
          vat_amount: vatString,
          tariff_code: testsData[shortName].tariff_code,
          // reduced_price: testsData[shortName].reduced_price,
        };
        batchUpdates.update(doc.ref, updateData);
      }
    });

    await batchUpdates.commit();
    console.log('Test results updated successfully!');
  } catch (error) {
    console.error('Error updating test results:', error);
  }
}
