import React, { useState, useEffect } from 'react';
import { Popup } from '../components';
import { usePatientSearch } from '../hooks';
import { updateOrder } from '../utils';
import { getUser } from '../utils';
import { moveOrderToDiscarded } from '../utils';
import Fuse from 'fuse.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import { OrderLimsPrintContainer } from './order_lims_print';
import { createLabRecordForm } from '../utils';
import { getFirestore } from 'firebase/firestore';
import { Timestamp } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';


export function ResultsInfoContainer ({orderInfo, setOrderInfo, trigger, setTrigger, doctors, phlebotomists, user, testSet, setTestSet}) {

  const firestore = getFirestore();
  const [showPopup, setShowPopup] = useState(false);

  const [selectedSite, setSelectedSite] = useState("");
  const [orderInfoLoc, setOrderInfoLoc] = useState({});
  const [orderInfoLocKey, setOrderInfoLocKey] = useState(0);
  const [patientSearch, setPatientSearch] = useState(0);
  
  const [doctorRows, setDoctorRows] = useState({});
  const [selectedDoctor, setSelectedDoctor] = useState({});

  const [patientRows, setPatientRows] = useState({});
  const [selectedPatient, setSelectedPatient] = useState({});
  const [patientSearchTerms, setPatientSearchTerms] = useState({patient_search_id: '', patient_search_email: '', patient_search_mobile: ''});
  var patientSearchRun = false;

  const { patients } = usePatientSearch(patientSearch,new Array(patientSearchTerms.patient_search_id,
      patientSearchTerms.patient_search_email,patientSearchTerms.patient_search_mobile));

  //Doctors Search Items
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerms, setSearchTerms] = useState({doctors_search_name: '', doctors_search_surname: '', doctors_search_location: ''});
  //const [doctorSearch, setDoctorSearch] = useState({doctors_first_name: '', doctors_surname: '',}); 

  const [yocoDate, setYocoDate] = useState(new Date());
  const [editMode, setEditMode] = useState(false);

  const fuse = new Fuse(doctors, {
    keys: ['name', 'surname'],
  });

  const dateOptions = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };

  useEffect (() => {
    if (patientSearchRun === false)
    {
      patientSearchRun = true;
      setPatientSearch(patientSearch + 1);
    }
  }, [patientSearchTerms]);
    
  
  useEffect (() => {
      handleSearch();
  }, [searchTerms]);

  useEffect (() => {
    if (trigger === false) {
      setSelectedDoctor({});
      setSelectedPatient({});    
      setPatientSearch(0);
    }
  }, [trigger]);

  useEffect (() => {
    const combinedArray = [];

    for (const item in searchResults) {
      const subObject = searchResults[item];
      combinedArray.push(subObject.item);
      }
    setDoctorRows(combinedArray);
  }, [searchResults]);
  
  useEffect(() => {
    const combinedArray = [];

    for (const item in patients) {
      const subObject = patients[item];
      combinedArray.push(subObject);
      }

    setPatientRows(combinedArray);
  }, [patients]);

  const handleSearch = () => {
    const query = `${searchTerms.doctors_search_name} ${searchTerms.doctors_search_surname}`.trim();

    if (query) {
      const searchResults = fuse.search(query);
      setSearchResults(searchResults);
    } else {
      setSearchResults([]);
    }
  };

  const downloadCSV = (event) => {
    event.preventDefault(); // Prevent default behavior
  
    // Create a comma-delimited string from the testSet data
    const csvContent = "data:text/csv;charset=utf-8,"
      + "Tariff Code,Test Category,Test Name,Price (ex. VAT),VAT,Amount\n"
      + testSet.map(item => {
        const tariffCode = item.tariff_code ? `"${item.tariff_code}"` : '';
        const reducedPrice = item.reduced_price ? parseFloat(item.reduced_price.replace(',', '.')) : 0;
        const vatAmount = item.vat_amount ? parseFloat(item.vat_amount.replace(',', '.')) : 0;
        const amount = (reducedPrice + vatAmount);
    
        // Format numbers with .00 if needed
        const formatNumber = (num) => num.toLocaleString('en-ZA', { minimumFractionDigits: 2, maximumFractionDigits: 2 }); 
    
        return `${tariffCode},${item.test_category},${item.test_name},"${formatNumber(reducedPrice)}","${formatNumber(vatAmount)}","${formatNumber(amount)}"`; 
      }).join('\n');
  
    const fileName = orderInfoLoc.patient_name + "_" + orderInfoLoc.patient_surname;
  
    // Create a hidden download link
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName + ".csv");
    document.body.appendChild(link);
  
    // Trigger the download
    link.click();
    document.body.removeChild(link);
  };

  
  async function cloudFileClick(path, action) {
    const storage = getStorage();
    const fileRef = ref(storage, path); 
  
    await getDownloadURL(fileRef)
      .then((url) => {
        if (action === "download") {
          // Create a hidden anchor element
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', ''); // Optional: Set filename if desired 
          link.target = '_blank'; 
          document.body.appendChild(link);

          link.click();
  
          // Cleanup the temporary element
          link.parentNode.removeChild(link); 
        }
      })
      .catch((error) => {
        console.error("Error getting download URL:", error);
      });
  }

  const handleChange = (e) => {
      const { id, value } = e.target;
      setOrderInfoLoc({ ...orderInfoLoc, [id]: value });
      setOrderInfoLocKey(prevKey => prevKey + 1);
  };

  const handlePhlebotomistChange = (e) => {
    const phleb_select = e.target;

    var phlebotomist_name = phleb_select.value;
    var phlebotomist_id = phleb_select.selectedOptions[0].id;

    if (phlebotomist_id === "none-selected") {
      setOrderInfoLoc({
        ...orderInfoLoc,
        phlebotomist_name: phlebotomist_name,
        phlebotomist_id: phlebotomist_id,
        dispatched_by_id: "",
        dispatched_by_name: "", //todo: make user name and surname compulsory in registration!
        dispatched_by_surname: "",
        dispatched_time : "",
      });
      setOrderInfoLocKey(prevKey => prevKey + 1);
      return;
    } 
    else 
    {
      // Update orderInfoLoc with phlebotomist and dispatch details
      setOrderInfoLoc({
        ...orderInfoLoc,
        phlebotomist_name: phlebotomist_name,
        phlebotomist_id: phlebotomist_id,
        dispatched_by_id: user.user_id,
        dispatched_by_name: user.name, //todo: make user name and surname compulsory in registration!
        dispatched_by_surname: user.surname,
        dispatched_time : new Date(),
      });
      setOrderInfoLocKey(prevKey => prevKey + 1);
    }
  };

  const handleYocoIDChange = (e) => {
    const { id, value } = e.target;

      setOrderInfoLoc({
        ...orderInfoLoc,
        yoco_id: value,
        yoco_date: yocoDate,
        payrecord_by_id: user.user_id,
        payrecord_by_name: user.name, //todo: make user name and surname compulsory in registration!
        payrecord_by_surname: user.surname,
        payrecord_time : new Date(),
      });
      setOrderInfoLocKey(prevKey => prevKey + 1);
  }

  const handleYocoAmountChange = (e) => {
    const { id, value } = e.target;

      setOrderInfoLoc({
        ...orderInfoLoc,
        yoco_amount: value,
        yoco_date: yocoDate,
        payrecord_by_id: user.user_id,
        payrecord_by_name: user.name, //todo: make user name and surname compulsory in registration!
        payrecord_by_surname: user.surname,
        payrecord_time : new Date(),
      });
      setOrderInfoLocKey(prevKey => prevKey + 1);
  }


  const handleSearchChange = (e) => {
    const { id, value } = e.target;
    //setOrderInfoLoc({ ...orderInfoLoc, [id]: value });
    setSearchTerms({ ...searchTerms, [id]: value });
    //setOrderInfoLocKey(prevKey => prevKey + 1);
  };

  const handlePatientSearchChange = (e) => {
    const { id, value } = e.target;
    //setOrderInfoLoc({ ...orderInfoLoc, [id]: value });
    setPatientSearchTerms({ ...patientSearchTerms, [id]: value });
    //setOrderInfoLocKey(prevKey => prevKey + 1);
  };


  useEffect(() => {

    if ( Object.keys(orderInfo).length > 0) {
      setOrderInfoLoc(orderInfo);
      setOrderInfoLocKey(prevKey => prevKey + 1);
    }

    setSearchTerms({doctors_search_name: orderInfo.doctors_first_name, doctors_search_surname: orderInfo.doctors_surname, doctors_search_location: orderInfo.doctors_location});

    const updatedPatientSearchTerms = {
      patient_search_id: orderInfo.patient_national_id,
      patient_search_email: orderInfo.email_address,
      patient_search_mobile: orderInfo.mobile_number,
    };
    patientSearchRun = false;
    setPatientSearchTerms(updatedPatientSearchTerms);

  }, [orderInfo]); 


  useEffect(() => {

   // if there is no phlebotomist in the database, set the dropdown box to index 0 (select a phlebotomist)
   if ( Object.keys(orderInfoLoc).length > 0) {
     if (Object.keys(orderInfoLoc).length > 0) {
        const optionToSelect = document.getElementById("phlebs");
      
        if (optionToSelect) {
          const userNameSurname = `${user.name} ${user.surname}`; // Combine name and surname
      
          let foundMatch = false; // Flag to track if a match is found
          for (let i = 0; i < optionToSelect.options.length; i++) {
            if (optionToSelect.options[i].text === userNameSurname) {
              optionToSelect.selectedIndex = i; // Set selection to the matching item
              foundMatch = true;
              break; // Exit loop since a match is found
            }
          }
      
          // If no match found, set selection to 0
          if (!foundMatch) {
            optionToSelect.selectedIndex = 0; 
          }
        }
      }
    }

    if ( Object.keys(orderInfoLoc).length > 0) {
      if (patientSearch === 0) {
        setPatientSearch(1);
      }

      if (orderInfoLoc.linked_doctor_id != null && orderInfoLoc.linked_doctor_id !== "") {
        getUser(orderInfoLoc.linked_doctor_id)
        .then((userResult) => {
          if (userResult.length > 0) {
          setSelectedDoctor(userResult[0]);
          }
        })
        .catch((errorMessage) => {
          //setError(errorMessage);
        });
      }

      if (orderInfoLoc.linked_patient_id != null && orderInfoLoc.linked_patient_id !== "") {
        getUser(orderInfoLoc.linked_patient_id)
        .then((userResult) => {
          if (userResult.length > 0) {
          setSelectedPatient(userResult[0]);
          }
        })
        .catch((errorMessage) => {
          //setError(errorMessage);
        });
      }
    }
  }, [orderInfoLocKey]); 

/*   useEffect(() => {
    setSearchTerms({doctors_first_name: orderInfoLoc.doctors_first_name, doctors_surname: orderInfoLoc.doctors_surname });
  }, [doctorSearch.doctors_surname, doctorSearch.doctors_first_name]);  */

  useEffect(() => {
    const combinedArray = [];
  
    for (const item in patients) {
      const subObject = patients[item];
      combinedArray.push(subObject);
      }

    setPatientRows(combinedArray);
  }, [patients]); 

  const selectPatientClick = (event) => {
    event.preventDefault();
    if (event.target.id !== "") {
      const foundObject = patientRows.find(obj => obj.user_id === event.target.id) 
      if (typeof foundObject !== "undefined") {
          setSelectedPatient(foundObject);
          orderInfoLoc.linked_patient_id = foundObject.user_id;
      }
    }
  }

  const refreshPatientList = (event) => {
    event.preventDefault();
    setPatientSearch(patientSearch + 1);
  }

  const selectDoctorClick = (event) => {
    event.preventDefault();
    if (event.target.id !== "") {
      const foundObject = doctorRows.find(obj => obj.user_id === event.target.id) 
      if (typeof foundObject !== "undefined") {
          setSelectedDoctor(foundObject);
          orderInfoLoc.linked_doctor_id = foundObject.user_id;
          orderInfoLoc.linked_practice_id = foundObject.practice_id;
          // orderInfoLoc.doctors_practice_name = foundObject.doctors_practice_name;
          orderInfoLoc.doctors_first_name = foundObject.name;
          orderInfoLoc.doctors_surname = foundObject.surname;
      }
    }
  }

  
 /*  useEffect (() =>{
    (selectedDoctor && Object.keys(selectedDoctor).length > 0)? orderInfoLoc.linked_doctor_id = selectedDoctor.user_id: delete orderInfoLoc.linked_doctor_id;;
    (selectedDoctor && Object.keys(selectedDoctor).length > 0)? orderInfoLoc.linked_practice_id = selectedDoctor.practice_id: delete orderInfoLoc.linked_practice_id;;
  }, [selectedDoctor]); */


  const unselectDoctorsClick = (event) => {
    event.preventDefault();
          setSelectedDoctor({});
          orderInfoLoc.linked_doctor_id = "";
          orderInfoLoc.linked_practice_id = "";
  }

  const unselectPatientsClick = (event) => {
    event.preventDefault();
          setSelectedPatient({});
          orderInfoLoc.linked_patient_id = "";
  }

  const patientSearchClick = (event) => {
      event.preventDefault();
      setPatientRows({});
      setSelectedPatient({});
      setPatientSearch(patientSearch+1);
  }

  const handleRadioInputChange = (event) => {

    var tempSiteID = event.target.id;
    
    if (tempSiteID !== null)
    {
      setSelectedSite(tempSiteID);
      setOrderInfoLoc((prevOrderInfoLoc) => ({
        ...prevOrderInfoLoc,
        ["phlebotomy_site"]: tempSiteID,
      }));
      setOrderInfoLocKey(prevKey => prevKey + 1);
    }
  }

  useEffect (()=> {
    var radioInputs = document.getElementsByName('selectSite');

    if ( Object.keys(orderInfo).length > 0) {
      if (orderInfo.phlebotomist_name !== null && orderInfo.phlebotomist_name !== "")
      {
        const optionToSelect = document.getElementById("phlebs");
  
        if (optionToSelect) {
          const options = optionToSelect.options;
          const valueToSelect = orderInfo.phlebotomist_name;
        
          for (let i = 0; i < options.length; i++) {
            if (options[i].value === valueToSelect) {
              optionToSelect.selectedIndex = i;
              break; // Exit the loop once a match is found
            }
          }
        }
      }

      // loop over all the site selection inputs
      for (var index =0; index < radioInputs.length; index++) {
    
        if (radioInputs[index].id === orderInfo.phlebotomy_site) {
          radioInputs[index].checked = true;   
        }   
      }
    }
  },[orderInfo]);
    
  function handleCloseClick() {
    setOrderInfoLoc({});
    setOrderInfoLocKey(prevKey => prevKey + 1);
  }

  async function printOrderClick() {
      createLabRecordForm(orderInfoLoc, testSet);
  }

  // Set the state of the order when saving, based on the data entered
  function setState() {
    if (orderInfoLoc.phlebotomist_id != null && orderInfoLoc.phlebotomist_id !== "") 
    {
      orderInfoLoc.status = "dispatched"; // a phlebotomist has been assigned
    }

    if (orderInfoLoc.requisition_number != null && orderInfoLoc.requisition_number != ""
          && orderInfoLoc.yoco_id != null && orderInfoLoc.yoco_id !== "") 
    {
      orderInfoLoc.status = "received" // payment has occurred and the sample has been collected and received at the lab
      const timestamp = Timestamp.now(); //firestore.Timestamp.now(); //get server timestamp, not local browser
      orderInfoLoc.sample_time = timestamp; 
      // todo: implement server side funcitons to process the tests now into paid orders
    }
  }
  const handleYocoDateChange = (date) => {
    setYocoDate(date);
  };

  useEffect(() => {
    const selectedName = orderInfoLoc.phlebotomist_name;
    // Find the option with the matching name and surname
    const optionToSelect = document.getElementById("phlebs");
  
    if (optionToSelect) {
      if (optionToSelect.value !== orderInfoLoc.phlebotomist_name) {
        // Set the selected value of the option to match orderInfoLoc.phlebotomist_name
        optionToSelect.value = orderInfoLoc.phlebotomist_name;
      }
    }
  }, [orderInfoLoc.phlebotomist_name]);

    return (
        <Popup trigger = {trigger} fullScreen = {true}>
          <Popup.LongContainer>
              <Popup.Title>Medical Test Order</Popup.Title>
          </Popup.LongContainer>
          <Popup.LongContainer>
              <Popup.Button onClick={printOrderClick}>Print Order</Popup.Button>
              <Popup.CloseButton id = "closeButton" setTrigger={setTrigger}>Close</Popup.CloseButton>
          </Popup.LongContainer>


       {/*    <OrderLimsPrintContainer orderInfo = {orderInfoLoc} trigger={showPopup} setTrigger={setShowPopup} 
              user={user} testSet={testSet}>
            </OrderLimsPrintContainer> */}

        <Popup.Item>
        <Popup.InputContainer>
        <Popup.Label>Patient:</Popup.Label>
        <Popup.Text>{orderInfoLoc.patient_name? orderInfoLoc.patient_name: null} 
          {orderInfoLoc.patient_surname? orderInfoLoc.patient_surname: null}</Popup.Text>
        </Popup.InputContainer>
        <Popup.InputContainer>
        <Popup.Label>Requisition Number:</Popup.Label>
        <Popup.Text>{orderInfoLoc.requisition_number? orderInfoLoc.requisition_number: null}</Popup.Text>
        </Popup.InputContainer>
        <Popup.DividingLine></Popup.DividingLine>
        {orderInfoLoc.ordered_date? <Popup.Text>Ordered: {new Date(orderInfoLoc.ordered_date.seconds * 1000).toLocaleDateString('en-ZA', dateOptions)}</Popup.Text>:<></>}
        {orderInfoLoc.phlebotomy_site === "4" ?
          <>
            {(orderInfoLoc.phlebotomy_fee && orderInfoLoc.phlebotomy_fee !== "") ?  <Popup.Text>Phlebotomy Fee: {parseFloat(orderInfoLoc.phlebotomy_fee.replace(',', '.')).toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })}</Popup.Text>: <></>}
          </>
        :<Popup.Text>Phlebotomy Fee: n/a</Popup.Text>}
        <Popup.Text>Test Status: {orderInfoLoc.status? orderInfoLoc.status: null}</Popup.Text>
        <Popup.DividingLine></Popup.DividingLine>

        <Popup.CaptionLeft>Patient's Details</Popup.CaptionLeft>
        <Popup.InputContainer>
            <Popup.Label htmlFor="patient name">Name:</Popup.Label>
            <Popup.Text>{orderInfoLoc.patient_name? orderInfoLoc.patient_name: null}</Popup.Text>
        </Popup.InputContainer>
        <Popup.InputContainer>
            <Popup.Label htmlFor="patient Surname">Surname:</Popup.Label>
            <Popup.Text>{orderInfoLoc.patient_surname? orderInfoLoc.patient_surname: null}</Popup.Text>
        </Popup.InputContainer>
        <Popup.InputContainer>
            <Popup.Label htmlFor="patient ID">ID:</Popup.Label>
            <Popup.Text>{orderInfoLoc.patient_national_id? orderInfoLoc.patient_national_id:null}</Popup.Text>
        </Popup.InputContainer>
      <Popup.InputContainer>
        <Popup.Label htmlFor="email_address">Email:</Popup.Label>
        <Popup.Text>{orderInfoLoc.email_address? orderInfoLoc.email_address: null}</Popup.Text>
      </Popup.InputContainer>
      <Popup.InputContainer>
            <Popup.Label htmlFor="patient mobile">Mobile:</Popup.Label>
            <Popup.Text>{orderInfoLoc.mobile_number? orderInfoLoc.mobile_number: null}</Popup.Text>
        </Popup.InputContainer>
        <form>

        <Popup.DividingLine></Popup.DividingLine>

        <Popup.CaptionLeft>Doctor's Details</Popup.CaptionLeft>
        <Popup.InputContainer>
          <Popup.Label htmlFor="doctors_location">Location:</Popup.Label>
          <Popup.Text>{orderInfoLoc.doctors_location? orderInfoLoc.doctors_location: null}</Popup.Text>
        </Popup.InputContainer>
        <Popup.InputContainer>
          <Popup.Label htmlFor="doctors_first_name">First Name:</Popup.Label>
          <Popup.Text>{orderInfoLoc.doctors_first_name? orderInfoLoc.doctors_first_name: null}</Popup.Text>
        </Popup.InputContainer>
        <Popup.InputContainer>
          <Popup.Label htmlFor="doctors_surname">Surname:</Popup.Label>
          <Popup.Text>{orderInfoLoc.doctors_surname? orderInfoLoc.doctors_surname: null}</Popup.Text>
        </Popup.InputContainer>
      <Popup.InputContainer>
          <Popup.Label htmlFor="doctors_practice_name">Practice Name:</Popup.Label>
          <Popup.Text>{orderInfoLoc.doctors_practice_name? orderInfoLoc.doctors_practice_name: null}</Popup.Text>
      </Popup.InputContainer>
      <Popup.InputContainer>
        <Popup.Label htmlFor="doctors_suburb">Suburb:</Popup.Label>
        <Popup.Text>{orderInfoLoc.doctors_suburb? orderInfoLoc.doctors_suburb: null}</Popup.Text>
      </Popup.InputContainer>
      <Popup.InputContainer>
        <Popup.Label htmlFor="doctors_city">Town / City:</Popup.Label>
        <Popup.Text>{orderInfoLoc.doctors_city}</Popup.Text>
      </Popup.InputContainer>
      <Popup.InputContainer>
        <Popup.Label htmlFor="doctors_province">Province:</Popup.Label>
        <Popup.Text>{orderInfoLoc.doctors_province}</Popup.Text>
      </Popup.InputContainer>
      <Popup.InputContainer>
        <Popup.Label htmlFor="doctors_telephone">Telephone:</Popup.Label>
        <Popup.Text>{orderInfoLoc.doctors_telephone}</Popup.Text>
      </Popup.InputContainer>

      <Popup.DividingLine></Popup.DividingLine>

      { Object.keys(selectedDoctor).length === 0? <><p>No Linked Doctor</p></> :  
        <>
             <Popup.Text>Currently linked doctor:</Popup.Text>

            <Popup.OptionButton type="button"
               id={selectedDoctor.user_id}  
              style={{background: 'rgba(204,102,0,0.75)'}}
            >
            <Popup.CardText id={selectedDoctor.user_id}>{selectedDoctor.name} {selectedDoctor.surname}</Popup.CardText>
            <Popup.CardText id={selectedDoctor.user_id}>{selectedDoctor.displayName}</Popup.CardText>
            <Popup.CardText id={selectedDoctor.user_id}>{selectedDoctor.email_address}</Popup.CardText>
            <Popup.CardText id={selectedDoctor.user_id}>{selectedDoctor.mobile_number}</Popup.CardText>
          </Popup.OptionButton>
        </>}

        { user.user_role === "admin" ? 
        <>
          <Popup.OptionButton onClick={unselectDoctorsClick}>
          <Popup.CardText >Remove Link to Selected Doctor</Popup.CardText>
          </Popup.OptionButton>

        <Popup.DividingLine></Popup.DividingLine>     
        <Popup.CaptionLeft>Doctor Search</Popup.CaptionLeft>
        <Popup.InputContainer>
          <Popup.Label htmlFor="doctors_search_name">Find Name:</Popup.Label>
          <Popup.Input
            type="text"
            id="doctors_search_name"
            value={searchTerms.doctors_search_name}
            onChange={handleSearchChange}
          />
        </Popup.InputContainer>
        <Popup.InputContainer>
          <Popup.Label htmlFor="doctors_search_surname">Find Surname:</Popup.Label>
          <Popup.Input
            type="text"
            id="doctors_search_surname"
            value={searchTerms.doctors_search_surname}
            onChange={handleSearchChange}
          />
        </Popup.InputContainer>

      <Popup.Text>Click to link the order a doctor:</Popup.Text>

      { Object.keys(doctorRows).length === 0 ? <><p>No Result Yet</p></> :  
        <>
 
        {doctorRows.map((doctorItem) => (
              <Popup.OptionButton 
                  id={doctorItem.user_id} 
                  onClick={selectDoctorClick} 
                >
                <Popup.CardText id={doctorItem.user_id}>{doctorItem.name} {doctorItem.surname}</Popup.CardText>
                <Popup.CardText id={doctorItem.user_id}>{doctorItem.displayName}</Popup.CardText>
                <Popup.CardText id={doctorItem.user_id}>{doctorItem.email_address}</Popup.CardText>
                <Popup.CardText id={doctorItem.user_id}>{doctorItem.mobile_number}</Popup.CardText>
              </Popup.OptionButton>
            ))}
            </>
          }
        </>
        :
          null
        }
        <Popup.DividingLine></Popup.DividingLine>

        { Object.keys(selectedPatient).length === 0? <><p>No Linked Patient</p></> :  
            <>
             <Popup.Text>Currently linked patient:</Popup.Text>

            <Popup.OptionButton type="button"
              id={selectedPatient.user_id}  
              style={{background: 'rgba(204,102,0,0.75)'}}
            >
            <Popup.CardText id={selectedPatient.user_id}>{selectedPatient.name} {selectedPatient.surname}</Popup.CardText>
            <Popup.CardText id={selectedPatient.user_id}>{selectedPatient.displayName}</Popup.CardText>
            <Popup.CardText id={selectedPatient.user_id}>{selectedPatient.email_address}</Popup.CardText>
            <Popup.CardText id={selectedPatient.user_id}>{selectedPatient.mobile_number}</Popup.CardText>
          </Popup.OptionButton>
        </>}

        { user.user_role === "admin" ? 
        <>
          <Popup.OptionButton onClick={unselectPatientsClick}>
            <Popup.CardText >Remove Link to Selected Patient</Popup.CardText>
          </Popup.OptionButton>
        <Popup.DividingLine></Popup.DividingLine>

        <Popup.CaptionLeft>Patient Search</Popup.CaptionLeft>
        <Popup.InputContainer>
          <Popup.Label htmlFor="patient_search_id">Find ID:</Popup.Label>
          <Popup.Text>{orderInfoLoc.patient_search_id}</Popup.Text>
          <Popup.Input
            type="text"
            id="patient_search_id"
            value={patientSearchTerms.patient_search_id}
            onChange={handlePatientSearchChange}
          />
        </Popup.InputContainer>
        <Popup.InputContainer>
          <Popup.Label htmlFor="patient_search_mobile">Find Mobile:</Popup.Label>
          <Popup.Input
            type="text"
            id="patient_search_mobile"
            value={patientSearchTerms.patient_search_mobile}
            onChange={handlePatientSearchChange}
          />
        </Popup.InputContainer>
        <Popup.InputContainer>
          <Popup.Label htmlFor="patient_search_email">Find Email:</Popup.Label>
          <Popup.Input
            type="text"
            id="patient_search_email"
            value={patientSearchTerms.patient_search_email}
            onChange={handlePatientSearchChange}
          />
        </Popup.InputContainer>

        
        { Object.keys(patientRows).length === 0 ? 
        <>        
          <Popup.LongContainer>
            <Popup.Text>(No Results from Patient Search Yet)</Popup.Text>
            <Popup.Button onClick={refreshPatientList}>Refresh List</Popup.Button>
          </Popup.LongContainer></> 
          : <>

          <Popup.LongContainer>
            <Popup.Text>Click to link the order to a patient:</Popup.Text>
            <Popup.Button onClick={refreshPatientList}>Refresh List</Popup.Button>
          </Popup.LongContainer>

          {patientRows.map((patientItem) => (
            <Popup.OptionButton 
                id={patientItem.user_id} 
                onClick={selectPatientClick} 
              >
              <Popup.CardText id={patientItem.user_id}>{patientItem.name} {patientItem.surname} </Popup.CardText>
               <Popup.CardText id={patientItem.user_id}>{patientItem.national_id} </Popup.CardText>
               <Popup.CardText id={patientItem.user_id}>{patientItem.mobile_number}</Popup.CardText>
               <Popup.CardText id={patientItem.user_id}>{patientItem.email_address}</Popup.CardText>
            </Popup.OptionButton>
          ))}
        </>
        }
        </>
        :
        null
        }

        <Popup.DividingLine></Popup.DividingLine> 
        
        <Popup.CaptionLeft>Sample Collection</Popup.CaptionLeft>
        <Popup.InputContainer>
          <Popup.Label>Sample Site: </Popup.Label>
          <Popup.Text>{orderInfo.phlebotomy_site_name}</Popup.Text>
        </Popup.InputContainer>

        <Popup.InputContainer>
          <Popup.Label htmlFor="phlebotomy_notes">Sample Notes:</Popup.Label>
          <textarea id="phlebotomy_notes" name="comments" rows="6" cols="50"
              type="text"
              value={orderInfoLoc.phlebotomy_notes}
              onChange={handleChange}>
          </textarea>
          </Popup.InputContainer>

          <Popup.InputContainer>
            <Popup.Label>Phlebotomist: </Popup.Label>
            <Popup.Text>{orderInfo.phlebotomist_name}</Popup.Text>
          </Popup.InputContainer>

      
            {/* <> 
            <Popup.Text>Phlebotomist:</Popup.Text>
            <Popup.LongContainer>
              <Popup.Select data-showpopup="no" name="phlebotomists" id="phlebs"
                onChange={handlePhlebotomistChange}>
              <option id = "none-selected" >Select a phlebotomist</option>
              { typeof phlebotomists == "undefined"  ? <></> : <>
              {phlebotomists.map((phleb_item) => (
                <>
                <option id = {phleb_item.user_id}>{phleb_item.name+" "+phleb_item.surname}</option>
                </>))}
                </>}
              </Popup.Select>
            </Popup.LongContainer>
            </> */}                             

            <Popup.DividingLine></Popup.DividingLine>

        <Popup.CaptionLeft>Payment</Popup.CaptionLeft>          
        { orderInfoLoc.payment_success === "true"? 
          <>
            <Popup.CardText>Successful Payfast Payment was Completed</Popup.CardText>
          </>:
          <>
            <Popup.InputContainer>
            <Popup.Label htmlFor="yoco_id">Payment ID:</Popup.Label>
            <Popup.Text>{orderInfoLoc.yoco_id? orderInfoLoc.yoco_id: null}</Popup.Text>
            </Popup.InputContainer>
            </>
        }
        <Popup.InputContainer>
        <Popup.Label htmlFor="yoco_amount">Payment Amount: R</Popup.Label>
        <Popup.Text>{orderInfoLoc.yoco_amount? orderInfoLoc.yoco_amount: null}</Popup.Text>
        </Popup.InputContainer>

        <Popup.InputContainer>
        <Popup.Label htmlFor="yoco_date">Yoco Date:</Popup.Label>

        {/* <Popup.Text>{orderInfoLoc.yoco_date? orderInfoLoc.yoco_date: null}</Popup.Text> */}
        <DatePicker id="yoco_date_picker"        
          selected={yocoDate}
          onChange={handleYocoDateChange}
          dateFormat="dd/MM/yyyy" //   placeholderText={'Payment Date'}
          isClearable
          />

        </Popup.InputContainer>

      <Popup.DividingLine></Popup.DividingLine>
      
      <Popup.InputContainer>
        <Popup.Label htmlFor="icd_10_code">ICD-10 Code:</Popup.Label>
        <Popup.Text>{orderInfoLoc.icd_10_code? orderInfoLoc.icd_10_code: null}</Popup.Text>
      </Popup.InputContainer>

      <Popup.DividingLine></Popup.DividingLine>

      <Popup.CaptionLeft>Tests In This Order:</Popup.CaptionLeft>               

      { Object.keys(testSet).length === 0 ? <><p>No tests found</p></> :  
        <>        
      {testSet.map((testItem) => (
  <Popup.CardText key={testItem.id || testItem.test_name}> {/* Add a unique key */}
    {testItem.tariff_code ? `(${testItem.tariff_code}) ` : null}
    {testItem.test_category || ''} : {testItem.test_name || ''} --- 
    {testItem.reduced_price 
      ? parseFloat(testItem.reduced_price.replace(',', '.')).toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' })
      : null
    }
  </Popup.CardText>
))}
        </>
      }
      {orderInfoLoc.order_total ? <Popup.CardText>{"(total order value: " + orderInfoLoc.order_total + " )"}</Popup.CardText> : null}
      {/* orderInfoLoc.invoice_path ? <Popup.Link href={orderInfoLoc.invoice_path} target="_blank" download>Get Invoice</Popup.Link>: null */}
      {orderInfoLoc.invoice_path ? <Popup.OptionButton onClick={(event) => {
         event.preventDefault();
        cloudFileClick(orderInfoLoc.invoice_path, "download");}}>
        Get Invoice</Popup.OptionButton>: null}
        <Popup.Button onClick={(e) => downloadCSV(e)}>Output to CSV</Popup.Button>
      <Popup.DividingLine></Popup.DividingLine>
      
      <Popup.Text>payment id: {orderInfoLoc.m_payment_id}</Popup.Text>
      <Popup.Text>ordered-by id: {orderInfoLoc.m_payment_id}</Popup.Text>

    </form>
    </Popup.Item>

      <Popup.LongContainer>
          <Popup.Button onClick={printOrderClick}>Save & Print Order</Popup.Button>
          <Popup.CloseButton id = "closeButton" setTrigger={setTrigger}>Close</Popup.CloseButton>
      </Popup.LongContainer>
    </Popup>
    );
}