import { useEffect, useState, useContext } from "react";
// import { FirebaseContext } from "../context/firebase";
import { getFirestore, collection, query, where, orderBy, limit, onSnapshot } from "firebase/firestore";

export default function useLabSearch(labSearch, limit_date_lower, limit_date_upper, patientID, patientSurname, practiceID, doctorID) {
  const [results, setResults] = useState([]);
  const db = getFirestore();

  useEffect(() => {
    const resultsRef = collection(db, "test_results");
    let searchQuery = query(resultsRef, orderBy('ordered_date', 'desc'), limit(500)); 

    if (limit_date_lower) {
      searchQuery = query(searchQuery, where('ordered_date', '>', limit_date_lower));
    }

    if (limit_date_upper) {
      searchQuery = query(searchQuery, where('ordered_date', '<', limit_date_upper));
    }

    if (patientID !== undefined && patientID !== null && patientID !== "") {
      searchQuery = query(searchQuery, where('patient_national_id', '==', patientID));
    }

    if (patientSurname !== undefined && patientSurname !== null && patientSurname !== "") {
      searchQuery = query(searchQuery, where('patient_surname', '==', patientSurname));
    }

    if (practiceID !== undefined && practiceID !== null && practiceID !== "") {
      searchQuery = query(searchQuery, where('linked_practice_id', '==', practiceID));
    }

    if (doctorID !== undefined && doctorID !== null && doctorID !== "") {
      searchQuery = query(searchQuery, where('linked_doctor_id', '==', doctorID));
    }

      // Subscribe to real-time updates (removed getDocs)
      const unsubscribe = onSnapshot(searchQuery, (querySnapshot) => {
        const allResults = querySnapshot.docs.map((resultsObj) => ({
          ...resultsObj.data(),
          docId: resultsObj.id
        }));
        setResults(allResults);
      }, (error) => {
        console.error("Error fetching results:", error);
      });
  
      return () => unsubscribe(); 
   /*  getDocs(searchQuery)
      .then((snapshot) => {
        const allResults = snapshot.docs.map((resultsObj) => ({
          ...resultsObj.data(),
          docId: resultsObj.id
        }));
        setResults(allResults);
      })
      .catch((error) => {
        console.error(error.message);
      }); */
  }, [labSearch, limit_date_lower, limit_date_upper, patientID, patientSurname, practiceID, doctorID]); 

  return { results };
}


/* import { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from '../context/firebase';

export default function useLabSearch(labSearch,limit_date_lower,limit_date_upper,array) {

  const [results, setResults] = useState([]);
  const { firebase } = useContext(FirebaseContext);

  useEffect(() => {
    let query = firebase
      .firestore()
      .collection('test_results')

    if (limit_date_lower !== undefined && limit_date_lower !== null) {
      query = query.where('request_date', '>', limit_date_lower);
    }

    if (limit_date_upper !== undefined && limit_date_upper !== null) {
      query = query.where('request_date', '<', limit_date_upper);
    }

    if (array[0] !== undefined && array[0] !== null && array[0] !== "") {
      query = query.where('patient_national_id', '==', array[0]);
    }

    if (array[1] !== undefined && array[1] !== null && array[1] !== "") {
      query = query.where('patient_surname', '==', array[1]);
    }

    if (array[2] !== undefined && array[2] !== null && array[2] !== "") {
      query = query.where('practice_id', '==', array[2]);
    }

    if (array[3] !== undefined && array[3] !== null && array[3] !== "") {
      query = query.where('doctor_id', '==', array[3]);
    }

    query
      .orderBy('request_date', 'desc')
      .limit(100)
      .get()
      .then((snapshot) => {
        const allResults = snapshot.docs.map((resultsObj) => ({
          ...resultsObj.data(),
          docId: resultsObj.id,
        }));

        setResults(allResults);
      })

      .catch((error) => {
        console.log(error.message);
      });
  }, [labSearch]);

  return { results };
}
 */