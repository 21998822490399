import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Results, Header, Loading, Popup } from '../components';
import * as ROUTES from '../constants/routes';

import { PayLaterInfoContainer } from './pay_later_order';
import { OrderInfoContainer } from './order';
import { ResultsInfoContainer } from './results';
import { MessageContainer } from './message';

import logo from '../logo.png';

// import { updateTestResults } from '../utils';
import { moveToResults } from '../utils';
import { UserContext } from '../context/UserContext';
import { SelectProfileContainer } from './profiles';
// import { EditTests } from './edit_tests';
import { selectionFilterResults } from '../utils';
import { selectionFilterLabOrders } from '../utils';
import { selectionFilterPayLaterOrders } from '../utils';
import { FooterContainer } from './footer';
import { useOrders } from '../hooks';
import { usePayLaterOrders } from '../hooks';
import { useIncompleteOrders } from '../hooks';
import { usePhlebotomists } from '../hooks';
import { usePractices } from '../hooks';
import { useDoctors } from '../hooks';
import { useLabMembers } from '../hooks';
import { useLabSearch } from '../hooks';
import { useHistory } from 'react-router-dom';
import { writeLabTests } from '../utils';
import { writeStatusUpdate } from '../utils';
import { writeTriggerResultsDemo } from '../utils';
import { writeTriggerReleaseReports } from '../utils';
import { deleteIncompleteOrder } from '../utils';
// import "firebase/storage";
import { getAuth, EmailAuthProvider } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getFirestore, collection, where, query, getDocs,doc, updateDoc } from 'firebase/firestore';

import Fuse from 'fuse.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

//to import lab test offering into database
import Papa from 'papaparse';
import {useDropzone} from 'react-dropzone'; 


export function LabContainer({props}) {
  const [reportPath, setReportPath, editProfile, setEditProfile, displayProfile, setDisplayProfile, userData, setIncompleteOrderId] = useContext(UserContext);
  const user = props.userData;
  const [profile, setProfile] = useState({});
  const [editProfileLoc, setEditProfileLoc] = useState({});
  const [searchedLabOrders, setSearchedLabOrders] = useState({});
  const [searchedPayLaterOrders, setSearchedPayLaterOrders] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [resultsDisplay, setResultsDisplay] = useState('date');
  const [labOrdersDisplay, setLabOrdersDisplay] = useState('status');
  /* const [labOrdersDisplayKey, setLabOrdersDisplayKey] = useState(0); */
  const [payLaterOrdersDisplay, setPayLaterOrdersDisplay] = useState('status');
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermPayLater, setSearchTermPayLater] = useState('');
  const [searchResults, setSearchResults] = useState({});
  const [resultRows, setResultRows] = useState({});
  const [labOrderRows, setLabOrderRows] = useState({});
  const [payLaterOrderRows, setPayLaterOrderRows] = useState({});
  const [labTestOffering, setLabTestOffering] = useState({});
  const history = useHistory();
  const { orders } = useOrders(user.user_role,user.user_id);
  const { incompleteOrders } = useIncompleteOrders(user.practice_id);
  const [testSet, setTestSet] = useState({});  //subset of tests with the same order_number for editing / updates
  const { payLaterOrders } = usePayLaterOrders(user.user_role,user.user_id);
  const [condensedRows, setCondensedRows] = useState({});
  const [condensedPayLaterRows, setCondensedPayLaterRows] = useState({});
  const [condensedResultRows, setCondensedResultRows] = useState({});

  const { labMembers } = useLabMembers(user.user_id);
  const { phlebotomists } = usePhlebotomists();
  const { practices } = usePractices();
  const { doctors } = useDoctors();
  const auth = getAuth();

  const [startDate, setStartDate] = useState();
  const [practiceID, setPracticeID] = useState();
  const [doctorID, setDoctorID] = useState();
  const [endDate, setEndDate] = useState();
  const [patientID, setPatientID] = useState();
  const [patientSurname, setPatientSurname] = useState();
  const [labSearch, setLabSearch] = useState(0);
  const { results } = useLabSearch(labSearch,startDate,endDate, patientID, patientSurname, practiceID, doctorID);

  const [orderInfo, setOrderInfo] = useState({});

  const [showPopup1, setShowPopup1] = useState(false);
  const [showPopup2, setShowPopup2] = useState(false);
  const [showPopup3, setShowPopup3] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageInfo, setMessageInfo] = useState({});
  const [scrollY, setScrollY] = useState(window.scrollY);
  const [yocoDate, setYocoDate] = useState(new Date());

  const [viewCompact, setViewCompact] = useState(null);
  const [viewIncomplete, setViewIncomplete] = useState(true);
  const [viewPending, setViewPending] = useState(null);
  const [viewInProcess, setViewInProcess] = useState(null);
  const [viewResults, setViewResults] = useState(null);

  const [messageCode, setMessageCode] = useState("");

  const [expandedPayLaterItems, setExpandedPayLaterItems] = useState({});
  const [expandedOrderItems, setExpandedOrderItems] = useState({});
  const [expandedResultItems, setExpandedResultItems] = useState({});
  //const [messageQueue, setMessageQueue] = useState(0);

  var prevPaymentId = 0;
  var gradient = 1;

  const dateOptions = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };


  //const storageRef = firebase.storage().ref();
  //cookies and methods to remember the view options set by the user
  useEffect(() => {
    try{
      const retrievedString = localStorage.getItem('EZPathViewSettings');
      const viewSettings = JSON.parse(retrievedString) || [true, true, false, false, true];;

      // Set default values if no cookie is found
      setViewCompact(viewSettings[0]); // Default to checked
      setViewCompact(viewSettings[4]); // Default to checked
      setViewPending(viewSettings[1]);
      setViewInProcess(viewSettings[2]);
      setViewResults(viewSettings[3]);
      
        document.getElementById("cb-compact").checked = viewSettings[0];
        document.getElementById("cb-incomplete").checked = viewSettings[4];
        document.getElementById("cb-pending").checked = viewSettings[1];
        document.getElementById("cb-in-process").checked = viewSettings[2];
        document.getElementById("cb-results").checked = viewSettings[3];

    } catch (error) {
      console.error("An error occurred:", error); 
    }
  }, []);


  const toggleExpand = (itemId, source) => {
    if (source === "paylater") {
      setExpandedPayLaterItems(prevExpanded => ({
        ...prevExpanded,
        [itemId]: !prevExpanded[itemId]
      }));
    }
    if (source === "order") {
      setExpandedOrderItems(prevExpanded => ({
        ...prevExpanded,
        [itemId]: !prevExpanded[itemId]
      }));
    }
    if (source === "result") {
      setExpandedResultItems(prevExpanded => ({
        ...prevExpanded,
        [itemId]: !prevExpanded[itemId]
      }));
    }
  };

  
  function toSentenceCase(str) {
    if(str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
    return "";
  }


  function FSTimestampToString(firestoreTimestamp) {
    // Convert Firestore timestamp to JavaScript Date
    const date = firestoreTimestamp.toDate();
    const options = {
      weekday: 'long',  // Get the full weekday name (e.g., "Monday")
      day: 'numeric',   // Get the day of the month as a number (e.g., "27")
      month: 'long',    // Get the full month name (e.g., "May")
      year: 'numeric',  // Get the full year (e.g., "2024")
      hour12: false,
      // hour: '2-digit',  // Get the hour in 24-hour format with leading zero (e.g., "14")
      // minute: '2-digit' // Get the minutes with leading zero (e.g., "00")
    };
    const formattedDate = date.toLocaleDateString("en-US", options); // Locale for day and month names
    const formattedTime = date.toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', hour12: false }); // Locale for time formatting
    return `${formattedDate} ${formattedTime}`;
  }


  const handleIncompleteOrderClick = (event) => {
    const customValue = event.target.getAttribute('data-showpopup');
    if (customValue === "no")
      return;

    let id  = event.target.id;
    if (id === "") {
      id = event.target.parentNode.id;
    }

    if (id !== "") {
      setIncompleteOrderId(id);
      history.push(ROUTES.ORDER_TESTS);
    }
  }


  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;

    // Update state based on which checkbox changed
    switch (id) {
      case "cb-compact":
        setViewCompact(checked);
        break;
      case "cb-incomplete":
          setViewIncomplete(checked);
        break;
      case "cb-pending":
        setViewPending(checked);
        break;
      case "cb-in-process":
        setViewInProcess(checked);
        break;
      case "cb-results":
        setViewResults(checked);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (viewCompact !== null && viewPending !== null && viewInProcess !== null && viewResults !== null)   {//don't store null initial values
    localStorage.setItem('EZPathViewSettings', JSON.stringify([viewCompact, viewPending, viewInProcess, viewResults, viewIncomplete])); 
  }
 /*  var checkbox_compact = document.getElementById('cb-compact');
  checkbox_compact.checked = viewCompact; */

  }, [viewCompact, viewResults, viewPending, viewInProcess]);
 // end of cookies to remember the view state set by the user



  //upload the lab test offering into Firebase
  function MyDropzone() {
    const onDrop = useCallback(acceptedFiles => {
      // Do something with the files
        const reader = new FileReader();
  
        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        //todo: iterate through the csv and write it all to Firebase. Delete the older version for now.
        //todo: can we do it all at once instead of line by line?
        //todo: data validity checks. order of columns / names of columns / data formatting / number of entries etc.
        //todo: feedback to user on success or otherwise of the upload operation
        //todo: display the Firebase contents so that the user can verify that it is accurate
        //todo: don't forget to put in a uuid for each line? is this even necessary at this stage
        //todo: add ability to revert to older versions if required and to store old versions in a seperate collection
        reader.onload = () => {
          Papa.parse(reader.result, {
            complete: function(results) {
              console.log("Finished:", results.data);
              console.log(results.data[0][3]);
              console.log(results.data[1][3]);
              console.log(results.data[2][3]);
              console.log(results.data[3][3]);
              console.log(results.data.length);
              console.log(results.data[0].length);
              writeLabTests(results.data);
            }
          });
        };
        // read file contents
      acceptedFiles.forEach(file => reader.readAsBinaryString(file));
      }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
      onDrop,
      accept: {
        'data/csv': ['.csv'],
      }
    })
  
    return (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {
          //isDragActive ?
          //  <p>Drop the files here ...</p> :
          //<p>Drag 'n' drop some files here, or click to select files</p>
          <>
          {user.user_role === "admin"? <Header.Button>Upload Tests</Header.Button>:<></>}
          </>
        }
      </div>
    )
  }


  useEffect(() => {
    if  (showPopup1 === false) {
      setOrderInfo({});
      window.scrollTo(0,scrollY);
    }
    else{
      setScrollY(window.scrollY);
      window.scrollTo(0,0);
    }
  }, [showPopup1])

  useEffect(() => {
    if  (showPopup2 === false) {
      setOrderInfo({});
      window.scrollTo(0,scrollY);
    }
    else{
      setScrollY(window.scrollY);
      window.scrollTo(0,0);
    }
  }, [showPopup2])

  // set the display profile right at the start based on the user. 
  // some GUI elements are hidden based on the user role
  useEffect(() => {
    if (Object.keys(user).length !== 0) {
      setDisplayProfile(user);
    }
    setIncompleteOrderId(""); //else order_tests page wil lload with aorder
  }, []);

  useEffect(() => {
    if (Object.keys(doctors).length !== 0) {
      console.log(doctors);
    }
    if (Object.keys(practices).length !== 0) {
      console.log(practices);
    }
  }, [doctors, practices]);

  useEffect(() => {
    if (Object.keys(editProfileLoc).length !== 0) {
      setEditProfile(editProfileLoc);
       history.push(ROUTES.LAB_MEMBER_DETAILS);
    }
  }, [editProfileLoc]);

  useEffect(() => {
    if (Object.keys(editProfile).length !== 0) {
      //setEditProfile(editProfileLoc);
       history.push(ROUTES.LAB_MEMBER_DETAILS);
    }
  }, [editProfile]);


  useEffect (() => {
    if (orders != null) {
      if (orders.length > 0 && searchTerm.length > 2) {
          const fuse = new Fuse(orders, { 
          keys: ['patient_name','requisition_number','test_name','patient_id','patient_surname'],
        });
        const search_results = fuse.search(searchTerm).map(({item}) => item);
        
        if (search_results.length > 0) {

    /*    // Sort the search results based on ordered_date
          search_results.sort((a, b) => {
          // Assuming ordered_date is in ISO8601 format or a format that can be directly compared
          const dateA = a.ordered_date;
          const dateB = b.ordered_date;
          
          // Sort in descending order, adjust '>' to '<' for ascending order
          return dateB - dateA;
          });  */


          setSearchedLabOrders(search_results);
        } else {
          setSearchedLabOrders({}); 
        }
      }
      else{
        setSearchedLabOrders({});
      }
    }
  }, [searchTerm]);

  useEffect (() => {
    if (orders != null) {
      if (payLaterOrders.length > 0 && searchTermPayLater.length > 2) {
          const fuse = new Fuse(payLaterOrders, { 
          keys: ['patient_name','requisition_number','test_name','patient_id','patient_surname'],
        });
        const search_results = fuse.search(searchTermPayLater).map(({item}) => item);
        if (search_results.length > 0) {
          setSearchedPayLaterOrders(search_results);
        } else {
          setSearchedPayLaterOrders({}); 
        }
      }
      else{
        setSearchedPayLaterOrders({});
      }
    }
  }, [searchTermPayLater]);

  useEffect(() => {
    if (payLaterOrders && payLaterOrders.length > 0) {
      if (Object.keys(searchedPayLaterOrders).length !== 0) {
        const pay_later_order_slides = selectionFilterPayLaterOrders(searchedPayLaterOrders, payLaterOrdersDisplay);
        setPayLaterOrderRows(pay_later_order_slides);
        const condensed_slides = selectionFilterPayLaterOrders(searchedPayLaterOrders, payLaterOrdersDisplay, true);   //todo: run this base don compactView variable
        setCondensedPayLaterRows(condensed_slides);
      } else {
        const pay_later_order_slides = selectionFilterPayLaterOrders(payLaterOrders, payLaterOrdersDisplay);
        setPayLaterOrderRows(pay_later_order_slides);
        const condensed_slides = selectionFilterPayLaterOrders(payLaterOrders, payLaterOrdersDisplay, true);   //todo: run this base don compactView variable
        setCondensedPayLaterRows(condensed_slides);
      }
    } 
  }, [payLaterOrders, searchedPayLaterOrders, payLaterOrdersDisplay, /* orderInfo */]);

  
  useEffect(() => {
    if (results !== null && results.length > 0) {
      const result_slides = selectionFilterResults(results, resultsDisplay);
      setResultRows(result_slides);
      const condensed_slides = selectionFilterResults(results, resultsDisplay, true);   //todo: run this based on compactView variable
      setCondensedResultRows(condensed_slides);
    }
  }, [results, resultsDisplay]);


  useEffect(() => {
    if (orders != null) {
      if (Object.keys(searchedLabOrders).length !== 0) {
        const lab_order_slides = selectionFilterLabOrders(searchedLabOrders, labOrdersDisplay);
        setLabOrderRows(lab_order_slides);
        const condensed_slides = selectionFilterLabOrders(searchedLabOrders, labOrdersDisplay, true);   //todo: run this base don compactView variable
        setCondensedRows(condensed_slides);
      } else {
        const lab_order_slides = selectionFilterLabOrders(orders, labOrdersDisplay);
        setLabOrderRows(lab_order_slides);
        const condensed_slides = selectionFilterLabOrders(orders, labOrdersDisplay, true);   //todo: run this base don compactView variable
        setCondensedRows(condensed_slides);
      }
    }
  }, [orders, searchedLabOrders, labOrdersDisplay]);
  
  /* useEffect(() => {
    if (orders != null) {
      if (Object.keys(searchedLabOrders).length !== 0) {
        const lab_order_slides = selectionFilterLabOrders(searchedLabOrders, labOrdersDisplay);
        setLabOrderRows(lab_order_slides);
      } else {
        const lab_order_slides = selectionFilterLabOrders(orders, labOrdersDisplay);
        setLabOrderRows(lab_order_slides);
      }
    }
  }, [orders, searchedLabOrders, labOrdersDisplay]); */

  
  useEffect(() => {
    if (payLaterOrders != null) {
      if (Object.keys(searchedPayLaterOrders).length !== 0) {
        const pay_later_order_slides = selectionFilterPayLaterOrders(searchedPayLaterOrders, payLaterOrdersDisplay);
        setPayLaterOrderRows(pay_later_order_slides);
      } else {
        const pay_later_order_slides = selectionFilterPayLaterOrders(payLaterOrders, payLaterOrdersDisplay);
        setPayLaterOrderRows(pay_later_order_slides);
      }
      
 /*      // Reassign and rerender the selected order page if required
      const matchingItem = payLaterOrders.find(payLaterOrder => payLaterOrder.test_name === orderInfo.test_name);
  
      // Reassign orderInfo if a matching item is found
      if (matchingItem) {
        setOrderInfo(matchingItem);
      } else {
        // Handle the case when no matching item is found
        console.log("No matching item found for the given test_name.");
      }
    */    } 
  }, [payLaterOrders, searchedPayLaterOrders, payLaterOrdersDisplay, orderInfo]);
  


  useEffect(() => {
    console.log("profile changed", profile);
    if (Object.keys(profile).length !== 0) {
      setDisplayProfile(profile);
    }
  }, [profile]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [profile.displayName]);

  
  function cloudFileClick(path, action) {
    const storage = getStorage();
    const fileRef = ref(storage, path); 
  
    getDownloadURL(fileRef)
      .then((url) => {
        if (action === "view") {
          setReportPath(url);
          history.push(ROUTES.VIEW_REPORT);
        } else if (action === "download") {
          // Create a hidden anchor element
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', ''); // Optional: Set filename if desired 
          document.body.appendChild(link);
  
          // Trigger the download
          link.click();
  
          // Cleanup the temporary element
          link.parentNode.removeChild(link); 
        }
      })
      .catch((error) => {
        console.error("Error getting download URL:", error);
      });
  }


  function DeleteQuote(event,item) { 
  
    event.preventDefault();
    
    const button = event.currentTarget;
    // const siblings = button.parentNode.children;
    const doc_id = button.id;
    // const source = button.getAttribute('data-source');     
    deleteIncompleteOrder(user.practice_id, doc_id);
	}


  function OrderStatusDispatched(event,item) { 
  
    event.preventDefault();
    
    const button = event.currentTarget;
    const siblings = button.parentNode.children;
    const doc_id = button.id;
    const source = button.getAttribute('data-source');


    
    var phleb_select = null;
    for(var i = siblings.length; i--;) {
        if(siblings[i].id == "phlebs") {
          phleb_select = siblings[i];
            break;
        }
    }

      if(phleb_select) {
        var phlebotomist_name = phleb_select.value;
        var phlebotomist_id = phleb_select.selectedOptions[0].id;

        if (phlebotomist_id == "none-selected") {
          alert("Please select a phlebotomist to proceed"); 
          return;
        }
        
        writeStatusUpdate(source, doc_id, "dispatched", user.user_id, user.name, user.surname, phlebotomist_id, phlebotomist_name, '', item.m_payment_id);
      }
	}
  
  
  function OrderStatusSampled(event,item) {  //sampling has been completed
  
    event.preventDefault();
    
    const button = event.currentTarget;
    const siblings = button.parentNode.children;
    const doc_id = button.id;
    const source = button.getAttribute('data-source');
    const checkBox = document.getElementById("cb-"+doc_id);
    const requisition_number = document.getElementById("req-"+doc_id).value;
    const yoco_id = document.getElementById("pay-id-"+doc_id).value;
    const yoco_amount = document.getElementById("pay-amount-"+doc_id).value;

    if (checkBox){
      if (checkBox.checked)
      {
        writeStatusUpdate(source, doc_id, "received", user.user_id, user.name, user.surname, requisition_number, yoco_id , yoco_amount, item.m_payment_id); 
      }
      else
      {
        writeStatusUpdate(source, doc_id, "sampled", user.user_id, user.name, user.surname, requisition_number, yoco_id , yoco_amount, item.m_payment_id); 
      }
    }
  }

  function OrderStatusReceived(event,item) {   //the sample has been received at the laboratory
  
      event.preventDefault();
      
      const button = event.currentTarget;
      const siblings = button.parentNode.children;
      const doc_id = button.id;
      const source = button.getAttribute('data-source');

      var req_number = document.getElementById("req-"+doc_id).value
  
      if (req_number == "") {
        setShowMessage(true);
        let mi = {};
        mi.title = "Warning";
        mi.caption = "Requisition Change";
        mi.line1 = "Please enter a requisition number to proceed.";
        mi.line2 = "";
        mi.confirmButton = "OK"; 
        mi.rejectButton = "";
        mi.messageCode = "";
        //mi.messageQueue = messageQueue;
        setMessageInfo(mi);

        //setMessageQueue(messageQueue + 1);
        //alert("Please enter a requisition number to proceed"); 
        return;
      }

      // Todo: write a message queue to uniquely identify messages and data
      // instead of window.confirm, show our own messagebox, save the writeStatusUpdate values to a state variable and call them when the message returns
      // in a useEffect function.
      if (item.requisition_number && req_number !== item.requisition_number)  // (we are about to change an existing requisition number)
      {
        var result = window.confirm("About to change an existing requisition number. Other asscoiated tests' requisition numbers will remain unchanged. Proceed?");
        if (!result) {
          return;    
        }        
      }

      writeStatusUpdate(source, doc_id, "received", user.user_id, user.name, user.surname, req_number, '', '', item.m_payment_id);
  }
  
  function signOut() {
    try {
      // Sign out the currently authenticated user
        auth.signOut().then(() => {
        console.log('User signed out successfully');
      });
    } catch (error) {
      console.error('Error signing out:', error);
    }
  }

  function editProfilesClick() {
    setEditProfileLoc(user);
  }

  function LabSearchClick() {
    if ((endDate !== undefined && endDate !== null) &&
        (startDate !== undefined && startDate !== null) &&
        (practiceID !== undefined && practiceID !== null && practiceID !== "") &&
        (doctorID !== undefined && doctorID !== null && doctorID !== ""))
      setLabSearch(0);
    else
      setLabSearch(labSearch+1);
  }

  function ClearSearchClick () {
    setLabSearch(0);
    setStartDate();
    setEndDate();
    setPracticeID();
    setDoctorID();

    document.getElementById("doctor").selectedIndex = 0;
    document.getElementById("practice").selectedIndex = 0;
    document.getElementById("datepicker").startDate = "";
    document.getElementById("patientID").value = "";
    document.getElementById("patientSurname").value = "";
  }

  const handleDateChange = (range) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handlePracticeChange = event => {
    let id = event.target.options[event.target.selectedIndex].id;
    setPracticeID(id);
  };

  function handlePayLaterItemClick(event,item) {
    const customValue = event.target.getAttribute('data-showpopup');
    if (customValue != "no") {
      setOrderInfo(item);
      setTestSet(payLaterOrders.filter(order => order.m_payment_id === item.m_payment_id));
      setShowPopup1(true);
    }
  }

  function handleCompactPayLaterItemClick(event,item) {
    const customValue = event.target.getAttribute('data-showpopup');
    if (customValue != "no") {
      setOrderInfo(item[0]);
      //setTestSet(payLaterOrders.filter(order => order.m_payment_id === item.m_payment_id));
      setTestSet(item);
      setShowPopup1(true);
    }
  }

  function handleOrderItemClick(event,item) {
    const customValue = event.target.getAttribute('data-showpopup');
    if (customValue != "no") {
      setOrderInfo(item);
      setTestSet(orders.filter(order => order.m_payment_id === item.m_payment_id));
      setShowPopup2(true);
    }
  }

  function handleResultItemClick(event,item) {
    const customValue = event.target.getAttribute('data-showpopup');
    if (customValue != "no") {
      setOrderInfo(item[0]);
      setTestSet(item);
      setShowPopup3(true);
    }
  }

  function handleCompactOrderItemClick(event,item) {
    const customValue = event.target.getAttribute('data-showpopup');
    if (customValue != "no") {
      setOrderInfo(item[0]);
      //setTestSet(orders.filter(order => order.m_payment_id === item[0]  .m_payment_id));
      setTestSet(item);
      setShowPopup2(true);
    }
  }

  const handleDoctorChange = event => {
    let id = event.target.options[event.target.selectedIndex].id;
    setDoctorID(id);
  };

  const handleSurnameChange = event => {
    let surname = event.target.value;
    setPatientSurname(surname);
  };

  const handleIDChange = event => {
    let id = event.target.value;
    setPatientID(id);
  };

  const handleYocoDateChange = (date) => {
    //event.preventDeafult
    setYocoDate(date);
  };


  async function uploadFinalResults(file, item) {
    //const orderId = item.m_payment_id; // replace with actual order ID
    //const testName = item.test_name;

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const folderPath = `Final Results/Oncolab/${year}_${month}`;
    var filePath = "";
    if (item[0].final_report_path && item[0].final_report_path !== "") {
      filePath = item[0].final_report_path;
    } else {
      filePath = `${folderPath}/${item[0].patient_surname}_${item[0].requisition_number}.pdf`; 
    }

    const storage = getStorage();
    const firestore = getFirestore();
    const storageRef = ref(storage, filePath);
    const uploadTask = uploadBytesResumable(storageRef, file);

    try {
      await uploadTask; 
      console.log('File uploaded successfully');

      // Store the file path directly in Firestore
      const orderItemsRef = doc(firestore, 'test_results', item[0].docId); 
      await updateDoc(orderItemsRef, {
        final_report_path: filePath 
      });

      console.log('File path saved to Firestore');
    } catch (error) {
      console.error('Error during upload or updating Firestore:', error);
    }
}

  function HandleFinalLinkOrder(event,item) {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.pdf';
    fileInput.addEventListener('change', (event) => {
      const file = event.target.files[0];
      if (file) {
        uploadFinalOrder(file, item);
      }
    });
    fileInput.click();
  }

  function HandleFinalLinkResults(event,item) {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.pdf';
    fileInput.addEventListener('change', (event) => {
      const file = event.target.files[0];
      if (file) {
        uploadFinalResults(file, item);
      }
    });
    fileInput.click();
  }

  async function uploadFinalOrder
  (file, item) {
    const orderId = item.m_payment_id; // replace with actual order ID
    const testName = item.test_name;

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const folderPath = `Final Results/Oncolab/${year}_${month}`;
    const filePath = `${folderPath}/${testName}_${orderId}.pdf`;

    const storage = getStorage();
    const firestore = getFirestore();
    const storageRef = ref(storage, filePath);
    const uploadTask = uploadBytesResumable(storageRef, file);

    try {
      await uploadTask; 
      console.log('File uploaded successfully');

      // Store the file path directly in Firestore
      const orderItemsRef = doc(firestore, 'order_items', item.docId); 
      await updateDoc(orderItemsRef, {
        final_report_path: filePath 
      });

      console.log('File path saved to Firestore');
    } catch (error) {
      console.error('Error during upload or updating Firestore:', error);
    }
}


async function uploadCumulative(file, item) {
  const orderId = item.m_payment_id; 
  const testName = item.test_name;

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; 
  const folderPath = `Cumulative Results/Oncolab/${year}_${month}`;
  const filePath = `${folderPath}/${orderId}.pdf`; // Construct the path

  const storage = getStorage();
  const firestore = getFirestore();
  const storageRef = ref(storage, filePath);
  const uploadTask = uploadBytesResumable(storageRef, file);

  try {
    await uploadTask; 
    console.log('File uploaded successfully');

    // Store the file path for later retrieval
    const orderItemsRef = collection(firestore, 'order_items');
    const orderItemsQuery = query(orderItemsRef, where('m_payment_id', '==', item.m_payment_id));

    const querySnapshot = await getDocs(orderItemsQuery);

    await Promise.all(querySnapshot.docs.map(async (orderItemDoc) => {
      await updateDoc(orderItemDoc.ref, {
        cumulative_report_path: filePath
      });
      console.log('File path saved to Firestore for document ID:', orderItemDoc.id);
    }));

  } catch (error) {
    console.error('Error during upload or updating Firestore:', error);
  }
}

async function uploadData(file, item) {
  const orderId = item.m_payment_id; 
  const testName = item.test_name;

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; 
  const folderPath = `Test Data/Oncolab/${year}_${month}`;
  const filePath = `${folderPath}/${testName}_${orderId}.pdf`; 

  const storage = getStorage();
  const firestore = getFirestore();
  const storageRef = ref(storage, filePath);
  const uploadTask = uploadBytesResumable(storageRef, file);

  try {
    await uploadTask; 
    console.log('File uploaded successfully');

    // Store the file path directly in Firestore
    const orderItemsRef = doc(firestore, 'order_items', item.docId); 
    await updateDoc(orderItemsRef, {
      data_file: filePath 
    });

    console.log('File path saved to Firestore');
  } catch (error) {
    console.error('Error during upload or updating Firestore:', error);
  }
}


  async function uploadPrelim(file, item) {
    const orderId = item.m_payment_id; 
    const testName = item.test_name;
  
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; 
    const folderPath = `Prelim Results/Oncolab/${year}_${month}`;
    const filePath = `${folderPath}/${testName}_${orderId}.pdf`;
  
    const storage = getStorage();
    const firestore = getFirestore();
    const storageRef = ref(storage, filePath);
    const uploadTask = uploadBytesResumable(storageRef, file);

    try {
      await uploadTask;
      console.log('File uploaded successfully');

      // Store the file path directly
      const orderItemsRef = doc(firestore, 'order_items', item.docId); 
      await updateDoc(orderItemsRef, {
        prelim_report_path: filePath // Store the path, not the download URL
      });

      console.log('File path saved to Firestore');
    } catch (error) {
      console.error('Error during upload or updating Firestore:', error);
    }
  }


  function HandleCumulativeLink(event,item) {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.pdf';
    fileInput.addEventListener('change', (event) => {
      const file = event.target.files[0];
      if (file) {
        uploadCumulative(file, item);
      }
    });
    fileInput.click();
  }


  function HandlePrelimLink(event,item) {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.pdf';
    fileInput.addEventListener('change', (event) => {
      const file = event.target.files[0];
      if (file) {
        uploadPrelim(file, item);
      }
    });
    fileInput.click();
  }


  function HandleDataLink(event,item) {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.pdf';
    fileInput.addEventListener('change', (event) => {
      const file = event.target.files[0];
      if (file) {
        uploadData(file, item);
      }
    });
    fileInput.click();
  }


  function messageClick() {
    setShowMessage(true);
    let mi = {};
    mi.title = "Warning";
    mi.caption = "Requisition Change";
    mi.line1 = "You are about to change the assigned requisition number.";
    mi.line2 = "The requisition for other associated tests will remain unchanged.";
    mi.confirmButton = "OK Proceed"; 
    mi.rejectButton = "Cancel";
    mi.messageCode = "PROCEED_WITH_REQ_UPDATE";
    setMessageInfo(mi);
  }


  useEffect(() => {
    let i;
    if (messageCode && messageCode !== "") {
      console.log("MessageCode: ", messageCode);
    }
  }, [messageCode]);

/*   const handleChange = (e) => {
    const { id, value } = e.target;
  
    // Remove "req-" from the id
    const shortId = id.replace("req-", "");
  
    // Copy the payLaterOrderRows to avoid mutating state directly
    const updatedPayLaterOrderRows = { ...payLaterOrderRows };
  
    // Loop through payLaterOrders
    updatedPayLaterOrderRows.payLaterOrders.forEach((slideItem) => {
      // Use map to iterate through data entries in each order
      slideItem.data.forEach((item) => {
        // Check if docId matches the shortId
        if (item.docId === shortId) {
          // Update requisition_number with the new value
          item.requisition_number = value;
        }
      });
    }); 
  
    // Set the updated payLaterOrderRows state
    setPayLaterOrderRows(updatedPayLaterOrderRows);
  
    console.log(updatedPayLaterOrderRows);
  };*/


  return (
    <>
      {loading  ? <Loading src={`/images/users/${displayProfile.photoURL}.png`} /> : <Loading.ReleaseBody />}
      { editMode ? 
          <SelectProfileContainer setEditProfile={setEditProfile}  user={user} setProfile={setProfile} dependants={labMembers} editMode={editMode} setEditMode={setEditMode}/>   
          :
          <>
            <MessageContainer trigger={showMessage} setTrigger={setShowMessage} title = {messageInfo.title} caption = {messageInfo.caption}
                line1={messageInfo.line1} line2={messageInfo.line2} confirmButton={messageInfo.confirmButton} rejectButton={messageInfo.rejectButton}
                  messageCode={messageInfo.messageCode} setMessageCode={setMessageCode}></MessageContainer>
            

            <PayLaterInfoContainer orderInfo = {orderInfo} setOrderInfo={setOrderInfo} trigger={showPopup1} setTrigger={setShowPopup1} 
              doctors={doctors} phlebotomists={phlebotomists} user={user} testSet={testSet} setTestSet={setTestSet}></PayLaterInfoContainer>
            
            <OrderInfoContainer orderInfo = {orderInfo} setorderInfo={setOrderInfo} trigger={showPopup2} setTrigger={setShowPopup2} 
              doctors={doctors} phlebotomists={phlebotomists} user={user} testSet={testSet} setTestSet={setTestSet}></OrderInfoContainer>

            <ResultsInfoContainer orderInfo = {orderInfo} setorderInfo={setOrderInfo} trigger={showPopup3} setTrigger={setShowPopup3} 
              doctors={doctors} phlebotomists={phlebotomists} user={user} testSet={testSet} setTestSet={setTestSet}></ResultsInfoContainer>

            <Header src="joker1" dontShowOnSmallViewPort>
              <Header.Frame>
                <Header.Group>
                  <Header.Logo to={ROUTES.LANDING} src={logo} alt="EZPath" />
                  <Header.Button onClick={() => editProfilesClick()}>My Details</Header.Button>
                  {(user.user_role == "admin" || user.user_role == "manager")?
                  <>
                    <Header.Button onClick={() => setEditMode(true)}>Lab Members</Header.Button>
                    <Header.ButtonLink to={ROUTES.EDIT_TESTS}>Edit Test Selection</Header.ButtonLink>
                    <Header.ButtonLink to={ROUTES.EDIT_SITES}>Edit Sites</Header.ButtonLink>
                    {/* <Header.Button onClick={() => writeTriggerResultsDemo()}>Process Demo</Header.Button> */}
                    <Header.ButtonLink to={ROUTES.PROCESS_RESULTS}>Results</Header.ButtonLink>
                    {/* <Header.Button onClick={() => writeTriggerReleaseReports()}>Release Reports</Header.Button> */}
                    <Header.Button onClick={() => moveToResults("100003962")}>Move to Results</Header.Button>
                    
                  </> : 
                  <></>} 
                  <Header.ButtonLink to={ROUTES.ORDER_TESTS}>Order a Test</Header.ButtonLink>
                  {/* <MyDropzone></MyDropzone> */}
                </Header.Group>
                <Header.Group>
                  <Header.Profile>
                    <Header.Picture src={displayProfile.photoURL} onClick={() => editProfilesClick()}></Header.Picture>
                    <Header.TextLink onClick={() => editProfilesClick()}>{displayProfile.name}</Header.TextLink>
                    <Header.Dropdown>  
                      { 
                        user.displayName === displayProfile.displayName ? 
                        <Header.Button onClick={() => signOut()}>Sign Out</Header.Button> 
                          :<Header.Group onClick={() => setProfile(user)}>
                            <Header.Picture src={user.photoURL} />
                            <Header.TextLink>{user.name}</Header.TextLink>
                          </Header.Group>
                      }
                      {/* {dependants.map((item) => (
                        item.displayName === displayProfile.displayName?
                        null
                        :<Header.Group key={item.user_id} onClick={() => setProfile(item)}>
                        <Header.Picture src={item.photoURL} 
                          data-testid="user-profile"/>
                        <Header.TextLink>{item.displayName}</Header.TextLink>
                      </Header.Group>
                      ))
                      } 
                      */}
                    </Header.Dropdown>
                  </Header.Profile>
                </Header.Group>
              </Header.Frame>

              <Header.Feature>
                <Header.FeatureCallOut>Lab Home</Header.FeatureCallOut>
                <Header.Text>
                  Welcome to EZPath Lab where you can manage your lab's interaction with patients and doctors.
                </Header.Text>
                <Results.LongContainer>
                <Results.SubTitle>VIEW OPTIONS:    </Results.SubTitle>
                <Results.CheckBox id={"cb-compact"} onChange={handleCheckboxChange}></Results.CheckBox>
                <Results.SubTitle>Compact View</Results.SubTitle>
                <Results.ButtonSpacer></Results.ButtonSpacer>
                <Results.CheckBox id={"cb-incomplete"} onChange={handleCheckboxChange}></Results.CheckBox>
                <Results.SubTitle>Incomplete Orders</Results.SubTitle>
                <Results.ButtonSpacer></Results.ButtonSpacer>
                <Results.CheckBox id={"cb-pending"} onChange={handleCheckboxChange}></Results.CheckBox>
                <Results.SubTitle>Pending Tests</Results.SubTitle>
                <Results.ButtonSpacer></Results.ButtonSpacer>
                <Results.CheckBox id={"cb-in-process"} onChange={handleCheckboxChange} defaultChecked={viewInProcess}></Results.CheckBox>
                <Results.SubTitle>Tests In Process</Results.SubTitle>
                <Results.ButtonSpacer></Results.ButtonSpacer>
                {(user.user_role == "admin" || user.user_role == "manager")?
                <>
                  <Results.CheckBox id={"cb-results"} onChange={(e) => setViewResults(e.target.checked)} defaultChecked={viewResults}></Results.CheckBox>
                  <Results.SubTitle>Results</Results.SubTitle>
                </>:<></>}
            </Results.LongContainer>
              </Header.Feature>
            </Header>
          
          
    { viewIncomplete === true? 
    <>
      <Results.Group>
        <Results.Heading>Quotations</Results.Heading>
        { Object.keys(incompleteOrders).length === 0 ? 
            <Results>
              <Results.Entities>
              <Results.Item gradient = {gradient}>
                <Results.SubTitle>No Quotations</Results.SubTitle>
              </Results.Item>
              </Results.Entities>
            </Results> 
            :  
          <>
          <Results>
            <Results.Entities>
            {incompleteOrders.map((order) => (
                  <Results.Item id={order.id}  gradient = {gradient} onClick={(event) => handleIncompleteOrderClick(event)}> 
                    <Results.SubTitle>Name: {order.orderInfo.name} {order.orderInfo.surname}</Results.SubTitle>
                    <Results.SubTitle>ID: {order.orderInfo.national_id}</Results.SubTitle>
                    <Results.SubTitle>Practitioner: {toSentenceCase(user.user_profession)} {order.orderInfo.doctors_first_name} {order.orderInfo.doctors_surname}</Results.SubTitle>
                    { <Results.SubTitle>{FSTimestampToString(order.orderInfo.timestamp)}</Results.SubTitle> }
                    <Results.Button data-source="delete" data-showpopup="no" id = {order.id} onClick={DeleteQuote}>Delete Quotation</Results.Button>
                  </Results.Item>
            ))}
            </Results.Entities>
            </Results>
          </>
        }
      </Results.Group>
      </>:
      <></>
  }



          { viewPending === true ?
            <>

        { viewCompact === true ? 
              <>
                <Results.Group>
                  {
                    <>
                      <Results.ButtonContainer>
                        <Results.Heading>Pending Test Orders</Results.Heading> { /*{displayProfile.displayName}'*/ /*todo put in Lan Name from DB)*/}
                        <Header.Search searchTerm={searchTermPayLater} setSearchTerm={setSearchTermPayLater} placeHolder="Test Name, Name, ID, Requisition" />
                        <Results.SubTitle>Arrange By:</Results.SubTitle>
                        <Results.ActionItem onClick={() => setPayLaterOrdersDisplay('status')}>Order Status</Results.ActionItem>
                        <Results.ActionItem onClick={() => setPayLaterOrdersDisplay('test_category')}>Category</Results.ActionItem>
                        <Results.ActionItem onClick={() => setPayLaterOrdersDisplay('ordered_date')}>Date</Results.ActionItem>
                        <Results.ActionItem onClick={() => setPayLaterOrdersDisplay('requisition_number')}>Requisition Number</Results.ActionItem>
                      </Results.ButtonContainer>
                    </>
                  }
                { Object.keys(condensedPayLaterRows).length === 0 ? 
                    <Results>
                      <Results.Entities>
                      <Results.Item gradient = {gradient}>
                        <Results.SubTitle>No Pending Tests</Results.SubTitle>
                      </Results.Item>
                      </Results.Entities>
                    </Results> 
                    :  
                    <>
                      {condensedPayLaterRows.payLaterOrders.map((slideItem, index) => (
                        
                          <Results key = {index}> 
                            <Results.Title>{slideItem.title}</Results.Title>
                            <Results.Entities>
                            {slideItem.data.length === 0 ? null : (
                        
                              <>
                                  {slideItem.data.map((item,slideIndex) => ( 
                                    
                                    <>
                                      <Results.Item id={item.docId} key={item.docId} gradient = {gradient} {...gradient = gradient + 1} onClick={(event) => handleCompactPayLaterItemClick(event, item)}>
                                      <Results.SubTitle>{item[0].patient_name + " " + item[0].patient_surname}</Results.SubTitle>
                                      <Results.DividingLine></Results.DividingLine>
                                      <Results.SubTitle>{item[0].patient_national_id}</Results.SubTitle>
                                      <Results.SubTitle>Req: {item[0].requisition_number? item[0].requisition_number : "no req. yet"}</Results.SubTitle> 
                                      { (item[0].status=='dispatched' && item.phlebotomy_site !== "4")?
                                  <>
                                    <Results.SubTitle>Status: Dispatched</Results.SubTitle>
                                    <Results.Text>EZPath has assigned a phlebotomist to assist the patient.</Results.Text>
                                    <Results.Text>The patient must visit their selected venue for sample collection.</Results.Text>
                                  </>: null
                                }
                                { (item[0].status=='dispatched' && item.phlebotomy_site === "4")?
                                  <>
                                    <Results.SubTitle>Status: Dispatched</Results.SubTitle>
                                    <Results.Text>EZPath has assigned a mobile phlebotomist to collect the sample.</Results.Text>
                                    <Results.Text>They will contact the patient shortly to arrange.</Results.Text>
                                  </> : null
                                }
                                { (item[0].status=='sampled')?
                                  <>
                                    <Results.SubTitle>Status: Sampled</Results.SubTitle>
                                    <Results.Text>The sample has been collected.</Results.Text>
                                    <Results.Text>It is on its way to the lab.</Results.Text>
                                </> : null
                                }
                                { (item[0].status=='ordered' && item.phlebotomy_site !== "4") ?
                                  <>
                                    <Results.SubTitle>Status: Ordered</Results.SubTitle>
                                    <Results.Text>The patient will visit their selected venue for sample collection.</Results.Text>
                                    <Results.Text>They must remember a card for payment.</Results.Text>
                                    {/* <Results.ActionText id = {item.m_payment_id} onClick={discardPloOrderClick}>...cancel this order</Results.ActionText> */}
                                    </> : null
                                }
                                { (item[0].status=='ordered' && item.phlebotomy_site === "4") ?
                                  <>
                                    <Results.SubTitle>Status: Ordered</Results.SubTitle>
                                    <Results.Text>EZPath will assign a mobile phlebotomist to collect the patient's sample.</Results.Text>
                                    <Results.Text>They will contact the patient shortly to arrange.</Results.Text>
                                    {/* <Results.ActionText id = {item.m_payment_id} onClick={discardPloOrderClick}>...cancel this order</Results.ActionText></>*/}
                                  </> : null
                                }
                                { (item[0].status=='received') ?
                                  <>
                                    <Results.SubTitle>Status: Received</Results.SubTitle>
                                    <Results.Text>The sample is safely at the lab.</Results.Text>
                                    <Results.Text>EZPath will let you know when the results are ready.</Results.Text>
                                  </> : null
                                }
                                <Results.Text> Ordered Date: {new Date(item[0].ordered_date.seconds * 1000).toLocaleDateString('en-ZA', dateOptions)}</Results.Text>
                                <Results.TestLine  data-showpopup="no" onClick={() => toggleExpand(slideIndex, "paylater")}> 
                                <span data-showpopup="no" style={{ cursor: 'pointer' }}>{expandedPayLaterItems[slideIndex] ? '-' : '+'}</span> {item.length} Tests...
                                </Results.TestLine>
                                  {expandedPayLaterItems[slideIndex] && (
                                      item.map((test, testIndex) => ( 
                                        <Results.TestLine key={testIndex}>{test.test_name}</Results.TestLine>
                                      ))
                                    )}
                                      </Results.Item>
                                    </>
                                  ))}
                            </>
                            )}
                            </Results.Entities>
                            </Results>
                          ))}
                      </>
                    }
                </Results.Group>
              </>
              :
              <>
                <Results.Group>
                  {<><Results.ButtonContainer>
                    <Results.Heading>Pending Orders</Results.Heading> { /*{displayProfile.displayName}'*/ /*todo put in Lan Name from DB)*/}
                    <Header.Search searchTerm={searchTermPayLater} setSearchTerm={setSearchTermPayLater} placeHolder="Test Name, Name, ID, Requisition" />
                    <Results.SubTitle>Arrange By:</Results.SubTitle>
                    <Results.ActionItem onClick={() => setPayLaterOrdersDisplay('status')}>Order Status</Results.ActionItem>
                    <Results.ActionItem onClick={() => setPayLaterOrdersDisplay('test_category')}>Category</Results.ActionItem>
                    <Results.ActionItem onClick={() => setPayLaterOrdersDisplay('ordered_date')}>Date</Results.ActionItem>
                    <Results.ActionItem onClick={() => setPayLaterOrdersDisplay('requisition_number')}>Requisition Number</Results.ActionItem>
                  </Results.ButtonContainer>
                  </>}
                  { Object.keys(payLaterOrderRows).length === 0 ? <><p>No Test Orders Pending</p></> :  
                  <>
                    {payLaterOrderRows.payLaterOrders.map((slideItem) => (
                      <Results id={displayProfile.user_id} key={`${displayProfile.user_id}-${slideItem.title}`}>
                        {slideItem.data.length === 0? null : (
                        <>
                          <Results.Title>{slideItem.title}</Results.Title>
                          <Results.Entities>
                          {slideItem.data.map((item) => {
                          if (item.m_payment_id !== prevPaymentId) {
                            prevPaymentId = item.m_payment_id;
                            gradient = gradient + 1;
                          }             
                        return (
                            <Results.Item id={item.docId} key={item.docId} gradient = {gradient} onClick={(event) => handlePayLaterItemClick(event, item)}>  {/*item={item} href={item.test_report_path}*/}
                            <Results.SubTitle>{item.test_name}</Results.SubTitle>
                            <Results.SubTitle>{item.patient_name} {item.patient_surname}</Results.SubTitle>
                            <Results.SubTitle>{item.patient_national_id}</Results.SubTitle>
                            <Results.SubTitle>Req: {item.requisition_number? item.requisition_number : "no req. yet"}</Results.SubTitle>
                              <Results.SubTitle>STATUS: {item.status}</Results.SubTitle>
                              <Results.SubTitle>Category: {item.test_category}</Results.SubTitle>
                              { item.status=='dispatched'?
                              <> 
                                <Results.SubTitle>Requisition: </Results.SubTitle>
                                <Results.LongContainer>
                                  {/* <Results.Text>{item.requisition_number}</Results.Text> */}
                                  <Results.SmallInput data-showpopup="no" id = {"req-"+item.docId} type="text" size="8" ></Results.SmallInput>
                                </Results.LongContainer>
                                
                                <Results.SubTitle>Payment ID: </Results.SubTitle>
                                <Results.LongContainer>
                                  {/* <Results.Text>{item.yoco_id}</Results.Text> */}
                                  <Results.SmallInput data-showpopup="no" id = {"pay-id-"+item.docId} type="text" size="8" ></Results.SmallInput>
                                </Results.LongContainer>
                            {/*<Results.SubTitle>Payment Date: </Results.SubTitle>
                              <DatePicker
                                  wrapperClassName="datePicker"
                                  data-showpopup="no"
                                  id="yoco_date_picker"        
                                  selected={item.yoco_date? item.yoco_date: yocoDate}
                                  onChange={handleYocoDateChange}
                                  dateFormat="dd/MM/yyyy" //   placeholderText={'Payment Date'}
                                  isClearable
                              /> */}
                              <Results.SubTitle>Amount: </Results.SubTitle>
                                    {item.order_total ? <Results.Text>{"(order value: " + item.order_total + " )"}</Results.Text> : null}
                                <Results.LongContainer>
                                {/* <Results.Text>{item.yoco_amount}</Results.Text> */}
                                  <Results.SmallInput data-showpopup="no" id = {"pay-amount-"+item.docId} type="text" size="8"></Results.SmallInput>
                                </Results.LongContainer>
                              </>
                              :
                              <>
                                {/* <Results.SubTitle>Requisition: {item.requisition_number} </Results.SubTitle> */}
                              </>}

                              { item.status=='sampled'?
                              <> 
                              <Results.SubTitle>Requisition: </Results.SubTitle>
                                <Results.LongContainer>
                                  {/* <Results.Text>{item.requisition_number}</Results.Text> */}
                                  <Results.SmallInput data-showpopup="no" id = {"req-"+item.docId} type="text" size="8"></Results.SmallInput>
                              </Results.LongContainer>
                              <Results.SubTitle>Payment ID: </Results.SubTitle>
                              <Results.LongContainer>
                                {/* <Results.Text>{item.yoco_id}</Results.Text>  */} 
                                <Results.SmallInput data-showpopup="no" id = {"pay-id-"+item.docId} type="text" size="8"></Results.SmallInput>
                              </Results.LongContainer>
                              <Results.SubTitle>Amount: </Results.SubTitle>
                              {item.order_total ? <Results.Text>{"(order value: " + item.order_total + " )"}</Results.Text> : null}
                              <Results.LongContainer>
                                  {/* <Results.Text>{item.yoco_amount}</Results.Text> */}
                                  <Results.SmallInput data-showpopup="no" id = {"pay-amount-"+item.docId} type="text" size="8"></Results.SmallInput>
                              </Results.LongContainer>
                              </>
                              :
                              <>
                                {/* <Results.SubTitle>Requisition: {item.requisition_number} </Results.SubTitle> */}
                              </>}

                              <Results.Text>Practice:  {item.doctors_practice_name}</Results.Text>
                              <Results.Text>Doctor:  {item.doctors_first_name} {item.doctors_surname}</Results.Text>
                              {(item.ordered_by_name &&  item.ordered_by_surname )?
                                <Results.Text>Ordered By:  {item.ordered_by_name + " " + item.ordered_by_surname}</Results.Text>:
                              <Results.Text>Ordered By: unknown</Results.Text>}

                              <Results.Text> Ordered Date: {new Date(item.ordered_date.seconds * 1000).toLocaleDateString('en-ZA', dateOptions)}
                              </Results.Text>
                              {/*<Results.Text>Payment Identifier:  {item.m_payment_id}</Results.Text>*/}
                              { item.status=='ordered'?
                              <> 
                              <Results.Select data-showpopup="no" name="phlebotomists" id="phlebs">
                              <option data-showpopup="no" id = "none-selected" >Select a phlebotomist</option>
                              { typeof phlebotomists == "undefined"  ? <></> : <>
                              {phlebotomists.map((phleb_item) => (
                                <>
                                <option data-showpopup="no" key = {phleb_item.user_id} id = {phleb_item.user_id}>{phleb_item.name+" "+phleb_item.surname}</option>
                                </>))}
                                </>}
                              </Results.Select>
                              <Results.Button data-source="order_pay_later_items" data-showpopup="no" id = {item.docId} onClick={(event) => OrderStatusDispatched(event, item)}>Dispatch Completed</Results.Button></>:
                              <>
                                <Results.SubTitle>Phlebotomist:  {item.phlebotomist_name}</Results.SubTitle>
                              </>}
                              { item.status=='dispatched'?
                              <> 
                                <Results.LongContainer>
                                  <Results.Text data-showpopup="no">Sample received at the lab</Results.Text>
                                  <Results.CheckBox id={"cb-"+item.docId} data-showpopup="no"></Results.CheckBox>
                                </Results.LongContainer>
                                  <Results.Button data-source="order_pay_later_items" data-showpopup="no" id = {item.docId} onClick={(event) => OrderStatusSampled(event, item)}>Sampling Completed</Results.Button>
                              </>:
                              <></>}
                              { item.status=='sampled'?
                              <> 
                              <Results.Button data-source="order_pay_later_items" data-showpopup="no" id = {item.docId} onClick={(event) => OrderStatusReceived(event, item)}>Receiving Completed</Results.Button></>:
                              <></>}

                            </Results.Item>
                            );
                          })}
                          </Results.Entities>
                          </>
                        )}
                        </Results>
                    ))}
                  </>
                  }
                </Results.Group>
              </>
              }
              </>
              :null}


            { viewInProcess === true ?
              <>
              { viewCompact === true ?
                <>
                {/* NEW ROLLUP TEST */}

                  <Results.Group>
                    {<><Results.LongContainer>
                      <Results.Heading>Tests in Process</Results.Heading>
                      <Header.Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} placeHolder="Test / Surname / ID / Req." />
                      {/* <Results.SubTitle>Show For:</Results.SubTitle>
                      <Results.Select  onChange={handleOrderDoctorChange} name="doctor" id="doctor">
                        <option id = "all-selected" >Whole Practice</option>
                        { typeof doctors == "undefined"  ? <></> : <>
                        {doctors.map((doctor_item) => (
                          <>
                            <option id = {doctor_item.user_id}>{doctor_item.displayName+" "+doctor_item.surname}</option>
                          </>))}
                        </>}
                      </Results.Select> */}
                      <Results.SubTitle>Arrange By:</Results.SubTitle>
                      <Results.ActionItem onClick={() => setLabOrdersDisplay('doctor_surname')}>Doctor</Results.ActionItem>
                      <Results.ActionItem onClick={() => setLabOrdersDisplay('status')}>Order Status</Results.ActionItem>
                      <Results.ActionItem onClick={() => setLabOrdersDisplay('category')}>Category</Results.ActionItem>
                      <Results.ActionItem onClick={() => setLabOrdersDisplay('ordered_date')}>Date</Results.ActionItem>
                    </Results.LongContainer>
                    </>}
                  { condensedRows === null ? 
                    <Results>
                      <Results.Entities>
                      <Results.Item>
                        <Results.SubTitle>No Orders for {displayProfile.displayName}</Results.SubTitle>
                      </Results.Item>
                      </Results.Entities>
                    </Results> :  
                    <>
                      {condensedRows.labOrders.map((slideItem, index) => (
                          <Results key = {index}> 
                            <Results.Title>{slideItem.title}</Results.Title>
                            <Results.Entities>
                            {slideItem.data.length === 0 ? null : (
                        
                              <>
                                  {slideItem.data.map((item, slideIndex) => ( 
                                    
                                    <>
                                      <Results.Item id={item.docId} key={item.docId} gradient={gradient} onClick={(event) => handleCompactOrderItemClick(event, item)} {...gradient = gradient + 1}>
                                      <Results.SubTitle>{item[0].patient_name + " " + item[0].patient_surname}</Results.SubTitle> 
                                      <Results.DividingLine></Results.DividingLine>
                                      <Results.SubTitle>{item[0].patient_national_id}</Results.SubTitle>
                                      <Results.SubTitle>Req: {item[0].requisition_number? item[0].requisition_number : "no req. yet"}</Results.SubTitle>
                                      { (item[0].status=='dispatched' && item.phlebotomy_site !== "4")?
                                  <>
                                    <Results.SubTitle>Status: Dispatched</Results.SubTitle>
                                    <Results.Text>EZPath has assigned a phlebotomist to assist the patient.</Results.Text>
                                    <Results.Text>The patient must visit their selected venue for sample collection.</Results.Text>
                                  </>: null
                                }
                                { (item[0].status=='dispatched' && item.phlebotomy_site === "4")?
                                  <>
                                    <Results.SubTitle>Status: Dispatched</Results.SubTitle>
                                    <Results.Text>EZPath has assigned a mobile phlebotomist to collect the sample.</Results.Text>
                                    <Results.Text>They will contact the patient shortly to arrange.</Results.Text>
                                  </> : null
                                }
                                { (item[0].status=='sampled')?
                                  <>
                                    <Results.SubTitle>Status: Sampled</Results.SubTitle>
                                    <Results.Text>The sample has been collected.</Results.Text>
                                    <Results.Text>It is on its way to the lab.</Results.Text>
                                </> : null
                                }
                                { (item[0].status=='ordered' && item.phlebotomy_site !== "4") ?
                                  <>
                                    <Results.SubTitle>Status: Ordered</Results.SubTitle>
                                    <Results.Text>The patient will visit their selected venue for sample collection.</Results.Text>
                                    <Results.Text>They must remember a card for payment.</Results.Text>
                                    {/* <Results.ActionText id = {item.m_payment_id} onClick={discardPloOrderClick}>...cancel this order</Results.ActionText> */}
                                    </> : null
                                }
                                { (item[0].status=='ordered' && item.phlebotomy_site === "4") ?
                                  <>
                                    <Results.SubTitle>Status: Ordered</Results.SubTitle>
                                    <Results.Text>EZPath will assign a mobile phlebotomist to collect the patient's sample.</Results.Text>
                                    <Results.Text>They will contact the patient shortly to arrange.</Results.Text>
                                    {/* <Results.ActionText id = {item.m_payment_id} onClick={discardPloOrderClick}>...cancel this order</Results.ActionText></>*/}
                                  </> : null
                                }
                                { (item[0].status=='received') ?
                                  <>
                                    <Results.SubTitle>Status: Received</Results.SubTitle>
                                    <Results.Text>The sample is safely at the lab.</Results.Text>
                                    <Results.Text>EZPath will let you know when the results are ready.</Results.Text>
                                  </> : null
                                }
                                <Results.Text> Ordered Date: {new Date(item[0].ordered_date.seconds * 1000).toLocaleDateString('en-ZA', dateOptions)}</Results.Text>
                                <Results.TestLine  data-showpopup="no" onClick={() => toggleExpand(slideIndex, "order")}> 
                                <span data-showpopup="no" style={{ cursor: 'pointer' }}>{expandedOrderItems[slideIndex] ? '-' : '+'}</span> {item.length} Tests...
                                </Results.TestLine>
                                  {expandedOrderItems[slideIndex] && (
                                      item.map((test, testIndex) => ( 
                                        <Results.TestLine key={testIndex}>{test.test_name}</Results.TestLine>
                                      ))
                                    )}
                                      </Results.Item>
                                    </>
                                  ))}
                            </>
                            )}
                            </Results.Entities>
                            </Results>
                          ))}
                    </>
                    }
                  </Results.Group>
                </>
                :

              <>
                <Results.Group>
                  {<><Results.ButtonContainer>
                    <Results.Heading>Oncolab's Tests in Process</Results.Heading> { /*{displayProfile.displayName}'*/ /*todo put in Lan Name from DB)*/}
                    <Header.Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} placeHolder="Test Name, Name, ID, Requisition" />
                    <Results.SubTitle>Arrange By:</Results.SubTitle>
                    <Results.ActionItem onClick={() => setLabOrdersDisplay('status')}>Order Status</Results.ActionItem>
                    <Results.ActionItem onClick={() => setLabOrdersDisplay('test_category')}>Category</Results.ActionItem>
                    <Results.ActionItem onClick={() => setLabOrdersDisplay('ordered_date')}>Date</Results.ActionItem>
                    <Results.ActionItem onClick={() => setLabOrdersDisplay('requisition_number')}>Requisition Number</Results.ActionItem>
                  </Results.ButtonContainer>
                  </>}
                  { Object.keys(labOrderRows).length === 0 ? <><Results.Text>No Orders Found</Results.Text></> :  
                  <>
                    {labOrderRows.labOrders.map((slideItem) => (
                      <Results id={displayProfile.user_id} key={`${displayProfile.user_id}-${slideItem.title}`}>
                        {slideItem.data.length === 0? null : (
                          <>
                        <Results.Title>{slideItem.title}</Results.Title>
                        <Results.Entities>
                        {slideItem.data.map((item) => {
                            if (item.m_payment_id !== prevPaymentId) {
                              prevPaymentId = item.m_payment_id;
                              gradient = gradient + 1;
                            }             
                          return (
                          <Results.Item id={item.docId} key={item.docId} gradient={gradient} onClick={(event) => handleOrderItemClick(event, item)}>  {/*item={item} href={item.test_report_path}*/}
                            <Results.SubTitle>STATUS: {item.status}</Results.SubTitle>
                            <Results.SubTitle>Category: {item.test_category}</Results.SubTitle>
                            { item.status=='sampled'?
                            <> 
                              <Results.SubTitle>Requisition:  
                              <input data-showpopup="no" id = {"req-"+item.docId} type="text" size="15"></input>
                              </Results.SubTitle>
                            </>
                            :
                            <>
                              <Results.SubTitle>Requisition: {item.requisition_number} </Results.SubTitle>
                            </>}  
                            <Results.Text>Test Name: {item.test_name}</Results.Text>
                            <Results.Text>Practice:  {item.doctors_practice_name}</Results.Text>
                            <Results.Text>Doctor:  {item.doctors_first_name} {item.doctors_surname}</Results.Text>
                            <Results.Text>Patient: {item.name} {item.surname}</Results.Text>
                            {(item.ordered_by_name &&  item.ordered_by_surname )?
                                <Results.Text>Ordered By:  {item.ordered_by_name + " " + item.ordered_by_surname}</Results.Text>:
                              <Results.Text>Ordered By: unknown</Results.Text>}
                            {item.ordered_date? <Results.Text> Ordered Date: {new Date(item.ordered_date.seconds * 1000).toLocaleDateString('en-ZA', dateOptions)}</Results.Text>:
                            <Results.Text> Ordered Date: blank</Results.Text>}
                            {/*<Results.Text>Payment Identifier:  {item.m_payment_id}</Results.Text>*/}
                            { item.status=='ordered'?
                            <> 
                            <Results.Select data-showpopup="no" name="phlebotomists" id="phlebs">
                            <option data-showpopup="no" id = "none-selected" >Select a phlebotomist</option>
                            { typeof phlebotomists == "undefined"  ? <></> : <>
                            {phlebotomists.map((phleb_item) => (
                              <>
                              <option data-showpopup="no" id = {phleb_item.user_id}>{phleb_item.name+" "+phleb_item.surname}</option>
                              </>))}
                              </>}
                            </Results.Select>
                            <Results.Button data-source="order_items" data-showpopup="no" id = {item.docId} onClick={OrderStatusDispatched}>Dispatch Completed</Results.Button></>:
                            <></>}
                            { item.status=='dispatched'?
                            <> 
                            <Results.Button data-source="order_items" data-showpopup="no" id = {item.docId} onClick={OrderStatusSampled}>Sampling Completed</Results.Button></>:
                            <></>}
                            { item.status=='sampled'?
                            <> 
                            <Results.Button data-source="order_items" data-showpopup="no" id = {item.docId} onClick={OrderStatusReceived}>Receiving Completed</Results.Button></>:
                            <></>}
                            { item.status=='received'?
                            <> 
                              
                              <Results.CloseTitle>Preliminary Report:</Results.CloseTitle>
                              {item.prelim_report_path?
                              <>
                                <Results.PlainContainer>
                                  <Results.CompactActionItem data-source="order_items" data-showpopup="no" id = {item.docId} onClick={() => cloudFileClick(item.prelim_report_path, "view")}>View</Results.CompactActionItem>
                                  <Results.CompactActionItem data-source="order_items" data-showpopup="no" id = {item.docId} onClick={(event) => HandlePrelimLink(event, item)}>Re-link</Results.CompactActionItem> 
                                </Results.PlainContainer>
                              </>:
                                <Results.Button data-source="order_items" data-showpopup="no" id = {item.docId} onClick={(event) => HandlePrelimLink(event, item)}>Link Report</Results.Button>
                              }

                              <Results.CloseTitle>Final Report:</Results.CloseTitle>
                              {item.final_report_path?
                              <>
                                <Results.PlainContainer>
                                  <Results.CompactActionItem data-source="order_items" data-showpopup="no" id = {item.docId} onClick={() => cloudFileClick(item.final_report_path, "view")}>View</Results.CompactActionItem>
                                  <Results.CompactActionItem data-source="order_items" data-showpopup="no" id = {item.docId} onClick={(event) => HandleFinalLinkOrder(event, item)}>Re-link</Results.CompactActionItem> 
                                </Results.PlainContainer>
                              </>:
                                <Results.Button data-source="order_items" data-showpopup="no" id = {item.docId} onClick={(event) => HandleFinalLinkOrder(event, item)}>Link Report</Results.Button>
                              }

                              <Results.CloseTitle>Cumulative:</Results.CloseTitle>
                              {item.cumulative_report_path? 
                              <>
                                <Results.PlainContainer>          
                                  <Results.CompactActionItem data-source="order_items" data-showpopup="no" id = {item.docId} onClick={() => cloudFileClick(item.cumulative_report_path, "view")}>View</Results.CompactActionItem>
                                  <Results.CompactActionItem data-source="order_items" data-showpopup="no" id = {item.docId} onClick={(event) => HandleCumulativeLink(event, item)}>Re-link</Results.CompactActionItem> 
                                </Results.PlainContainer>
                              </>
                              :
                                <Results.Button data-source="order_items" data-showpopup="no" id = {item.docId} onClick={(event) => HandleCumulativeLink(event, item)}>Link Cumulative</Results.Button>
                              }

                              <Results.CloseTitle>Raw Data:</Results.CloseTitle>
                              {item.data_file? 
                              <>
                                <Results.PlainContainer> 
                                  <Results.CompactActionItem data-source="order_items" data-showpopup="no" id = {item.docId} onClick={() => cloudFileClick(item.data_link_path, "view")}>View</Results.CompactActionItem>
                                  <Results.CompactActionItem data-source="order_items" data-showpopup="no" id = {item.docId} onClick={(event) => HandleDataLink(event, item)}>Re-link</Results.CompactActionItem> 
                                </Results.PlainContainer> 
                              </>
                              :
                              <Results.Button data-source="order_items" data-showpopup="no" id = {item.docId} onClick={(event) => HandleDataLink(event, item)}>Link Data</Results.Button>
                              }
                            </>
                            :
                            <></>
                            }                  
                            </Results.Item>
                            );
                          })}
                          </Results.Entities>
                          </>
                        )}
                        </Results>
                    ))}
                  </>
                  }
            </Results.Group>
            </>
            }
            </>
            :
            <></>
            }
            { viewResults === true && (user.user_role == "admin" || user.user_role == "manager")? 
              <>
                <Results.Group>
                  <>
                    <Results.ButtonContainer>
                      <Results.Heading>Oncolab's Latest Test Results</Results.Heading>
                      <Results.SubTitle>Arrange By:</Results.SubTitle>
                      <Results.ActionItem onClick={() => setResultsDisplay('category')}>Category</Results.ActionItem>
                      <Results.ActionItem onClick={() => setResultsDisplay('date')}>Date</Results.ActionItem>
                      <Results.ActionItem onClick={() => setResultsDisplay('practice')}>Practice</Results.ActionItem>
                    </Results.ButtonContainer>
                    <Results.LongContainer>
                      <Results.SubTitle>Search By:</Results.SubTitle>
                      <DatePicker id="datepicker" selected={startDate} placeholderText={'Test Date Range'} onChange={handleDateChange} startDate={startDate} endDate={endDate} selectsRange isClearable />
                      <Results.Select data-showpopup="no" onChange={handlePracticeChange} name="practice" id="practice">
                      <option>Select a practice</option>
                      {typeof practices == "undefined" ? 
                        <></> 
                        : 
                        <>
                          {practices.map((practice_item) => (
                            <>
                              <option id={practice_item.user_id}>{practice_item.displayName + " " + practice_item.surname}</option>
                            </>
                          ))}
                        </>
                      }
                      </Results.Select>
                      <Results.Select data-showpopup="no" onChange={handleDoctorChange} name="doctor" id="doctor">
                        <option id="none-selected">Select a doctor</option>
                        {typeof doctors == "undefined" ? <></> : <>
                          {doctors.map((doctor_item) => (
                            <>
                              <option id={doctor_item.user_id}>{doctor_item.displayName + " " + doctor_item.surname}</option>
                            </>
                          ))}
                        </>}
                      </Results.Select>
                      <Results.Input data-showpopup="no" id="patientID" placeholder="Patient ID" defaultValue={""} onChange={handleIDChange} />
                      <Results.Input data-showpopup="no" id="patientSurname" placeholder="Patient Surname" defaultValue={""} onChange={handleSurnameChange} />
                    </Results.LongContainer>
                    <Results.LongContainer>
                      <Results.ActionItem onClick={() => LabSearchClick()}>Search Now</Results.ActionItem>
                      <Results.ActionItem onClick={() => ClearSearchClick()}>Clear Search</Results.ActionItem>
                    </Results.LongContainer>
                  </>
                </Results.Group>
            
                { condensedResultRows === null || condensedResultRows === undefined || Object.keys(condensedResultRows).length === 0 ? 
                  <>
                    <Results>
                      <Results.Entities>
                      <Results.Item gradient = {gradient}>
                        <Results.SubTitle>No Compact Results Available</Results.SubTitle>
                      </Results.Item>
                      </Results.Entities>
                    </Results>
                  </> 
                  :  
                  <>
                    {condensedResultRows.results.map((slideItem, index) => (
                      <Results key = {index}> 
                        <Results.Title>{slideItem.title}</Results.Title>
                        <Results.Entities>
                          {slideItem.data.length === 0 ? null 
                            : 
                            (
                              <>
                                {slideItem.data.map((item, slideIndex) => ( 
                                  <>
                                    <Results.Item id={item.docId} key={item.docId} gradient = {gradient} {...gradient = gradient + 1} onClick={(event) => handleResultItemClick(event, item)}>
                                    <Results.SubTitle>{item[0].patient_name + " " + item[0].patient_surname}</Results.SubTitle>
                                    <Results.DividingLine></Results.DividingLine>
                                    <Results.SubTitle>{item[0].patient_national_id}</Results.SubTitle>
                                    <Results.SubTitle>Req: {item[0].requisition_number? item[0].requisition_number : "no req. yet"}</Results.SubTitle> 
                                    <Results.Text> Doctor: {item[0].doctors_first_name + " " + item[0].doctors_surname}</Results.Text>
                                    <Results.Text> Ordered Date: {new Date(item[0].ordered_date.seconds * 1000).toLocaleDateString('en-ZA', dateOptions)}</Results.Text>
                                    <Results.Text> Collection Site: {item[0].phlebotomy_site_name}</Results.Text>
                                    <Results.SubTitle> Phlebotomist: {item[0].phlebotomist_name}</Results.SubTitle>
                                    {/* todo: only enable download and view buttons if report actually exists in database */}
                                    <Results.TestLine  data-showpopup="no" onClick={() => toggleExpand(slideIndex, "result")}> 
                                      <span data-showpopup="no" style={{ cursor: 'pointer' }}>{expandedResultItems[slideIndex] ? '-' : '+'}</span> {item.length} Tests...
                                    </Results.TestLine>
                                    {expandedResultItems[slideIndex] && (
                                      item.map((test, testIndex) => ( 
                                        <Results.TestLine key={testIndex}>{test.test_name}</Results.TestLine>
                                      ))
                                    )}
                                    {/* <Results.ButtonLink to={ ROUTES.VIEW_REPORT }>View Report</Results.ButtonLink> */}
                                    {!item[0].final_report_path && item[0].prelim_report_path?
                                      <>
                                      <Results.ButtonDownload onClick={() => cloudFileClick(item[0].prelim_report_path, "view")}>View Preliminary Report</Results.ButtonDownload>
                                      <Results.ButtonDownload onClick={() => cloudFileClick(item[0].prelim_report_path, "download")}>Download Preliminary Report</Results.ButtonDownload>
                                      </>
                                      : <></>
                                    }
                                    {item[0].final_report_path ?
                                      <>
                                      <Results.ButtonDownload onClick={() => cloudFileClick(item[0].final_report_path, "view")}>View Final Report</Results.ButtonDownload>
                                      <Results.ButtonDownload onClick={() => cloudFileClick(item[0].final_report_path, "download")}>Download Final Report</Results.ButtonDownload>
                                      </>
                                      : <></>
                                    }
                                    {item.cumulative_report_path ?
                                      <>
                                      <Results.ButtonDownload onClick={() => cloudFileClick(item[0].cumulative_report_path, "view")}>View Cumulative Report</Results.ButtonDownload>
                                      <Results.ButtonDownload onClick={() => cloudFileClick(item[0].cumulative_report_path, "download")}>Download Cumulative Report</Results.ButtonDownload>
                                      </>
                                      : <></>
                                    }
                                    <Results.CompactActionItem data-source="order_items" data-showpopup="no" id = {item.docId} onClick={(event) => HandleFinalLinkResults(event, item)}>Re-link Report</Results.CompactActionItem> 
                                  </Results.Item>
                                  </>
                                ))}
                              </>
                            )
                          }
                        </Results.Entities>
                      </Results>
                    ))}
                  </>
                } 
              </>:null
            }                           
            {/*end of results section */}

            <FooterContainer />
            </>
          }
          </>
  )
}